import {
  ADD_DATEPICKER,
  ADD_DATES,
  CLEAR_DATEPICKER_BLACKOUT_DATES,
  DELETE_DATE,
  GET_CALENDAR_BY_DATE,
  GET_CALENDAR_BY_REGION,
  GET_DATEPICKER_BLACKOUT_DATES,
  GET_PAST_DATES,
  GET_UPCOMING_DATES,
  REMOVE_DATEPICKER,
  SET_CALENDAR_BY_DATE,
  SET_CALENDAR_BY_REGION,
  SET_DATEPICKER_BLACKOUT_DATES,
  SET_DATEPICKER_INDEX,
  SET_PAST_DATES,
  SET_REMOVING_DATE,
  SET_SAVING_DATE,
  SET_SELECTED_MONTH_YEAR,
  SET_SELECTED_REGION,
  SET_UPCOMING_DATES,
  STOP_LOADING_CALENDAR,
  UPDATE_DATE,
} from "redux/constants";

export const stopLoadingCalendar = (payload) => ({
  type: STOP_LOADING_CALENDAR,
  payload,
});

export const setSelectedMonthYear = (payload) => ({
  type: SET_SELECTED_MONTH_YEAR,
  payload,
});

export const setSelectedRegion = (payload) => ({
  type: SET_SELECTED_REGION,
  payload,
});

export const getCalendarByRegion = (payload) => ({
  type: GET_CALENDAR_BY_REGION,
  payload,
});

export const setCalendarByRegion = (payload) => ({
  type: SET_CALENDAR_BY_REGION,
  payload,
});

export const getCalendarByDate = (payload) => ({
  type: GET_CALENDAR_BY_DATE,
  payload,
});

export const setCalendarByDate = (payload) => ({
  type: SET_CALENDAR_BY_DATE,
  payload,
});

export const getUpcomingDates = (payload) => ({
  type: GET_UPCOMING_DATES,
  payload,
});

export const setUpcomingDates = (payload) => ({
  type: SET_UPCOMING_DATES,
  payload,
});

export const getPastDates = (payload) => ({
  type: GET_PAST_DATES,
  payload,
});

export const setPastDates = (payload) => ({
  type: SET_PAST_DATES,
  payload,
});

export const addDates = (payload) => ({
  type: ADD_DATES,
  payload,
});

export const deleteDate = (payload) => ({
  type: DELETE_DATE,
  payload,
});

export const updateDate = (payload) => ({
  type: UPDATE_DATE,
  payload,
});

export const getDatepickerBlackoutDates = (payload) => ({
  type: GET_DATEPICKER_BLACKOUT_DATES,
  payload,
});

export const setDatepickerBlackoutDates = (payload) => ({
  type: SET_DATEPICKER_BLACKOUT_DATES,
  payload,
});

export const setDatepickerIndex = (payload) => ({
  type: SET_DATEPICKER_INDEX,
  payload,
});

export const addDatepicker = (payload) => ({
  type: ADD_DATEPICKER,
  payload,
});

export const removeDatepicker = (payload) => ({
  type: REMOVE_DATEPICKER,
  payload,
});

export const clearDatepickerBlackoutDates = (payload) => ({
  type: CLEAR_DATEPICKER_BLACKOUT_DATES,
  payload,
});

export const setSavingDate = (payload) => ({
  type: SET_SAVING_DATE,
  payload,
});

export const setRemovingDate = (payload) => ({
  type: SET_REMOVING_DATE,
  payload,
});
