export const STOP_LOADING_CALENDAR = "STOP_LOADING_CALENDAR";
export const GET_SELECTED_MONTH_YEAR = "GET_SELECTED_MONTH_YEAR";
export const SET_SELECTED_MONTH_YEAR = "SET_SELECTED_MONTH_YEAR";
export const GET_SELECTED_REGION = "GET_SELECTED_REGION";
export const SET_SELECTED_REGION = "SET_SELECTED_REGION";
export const GET_CALENDAR_BY_REGION = "GET_CALENDAR_BY_REGION";
export const SET_CALENDAR_BY_REGION = "SET_CALENDAR_BY_REGION";
export const GET_CALENDAR_BY_DATE = "GET_CALENDAR_BY_DATE";
export const SET_CALENDAR_BY_DATE = "SET_CALENDAR_BY_DATE";
export const GET_UPCOMING_DATES = "GET_UPCOMING DATES";
export const SET_UPCOMING_DATES = "SET_UPCOMING_DATES";
export const GET_PAST_DATES = "GET_PAST_DATES";
export const SET_PAST_DATES = "SET_PAST_DATES";
export const ADD_DATES = "ADD_DATES";
export const DELETE_DATE = "DELETE_DATE";
export const UPDATE_DATE = "UPDATE_DATE";
export const GET_DATEPICKER_BLACKOUT_DATES = "GET_DATEPICKER_BLACKOUT_DATES";
export const SET_DATEPICKER_BLACKOUT_DATES = "SET_DATEPICKER_BLACKOUT_DATES";
export const SET_DATEPICKER_INDEX = "SET_DATEPICKER_INDEX";
export const ADD_DATEPICKER = "ADD_DATEPICKER";
export const REMOVE_DATEPICKER = "REMOVE_DATEPICKER";
export const CLEAR_DATEPICKER_BLACKOUT_DATES =
  "CLEAR_DATEPICKER_BLACKOUT_DATES";
export const SET_SAVING_DATE = "SET_SAVING_DATE";
export const SET_REMOVING_DATE = "SET_REMOVING_DATE";
