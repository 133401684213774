import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SideNav from "components/layout-components/SideNav";
import TopNav from "components/layout-components/TopNav";
import Loading from "components/shared-components/Loading";
import MobileNav from "components/layout-components/MobileNav";
import HeaderNav from "components/layout-components/HeaderNav";
import Footer from "components/layout-components/Footer";
import { Layout, Grid, notification } from "antd";

import {
  attendanceManagement,
  podManagement,
  adminProfile,
  staffManagement,
  studentDashboard,
  studentManagement,
  studentProfile,
  studentSession,
  staffDashboard,
  staffProfile,
  blackoutCalendar,
} from "configs/NavigationConfig";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR,
  ADMIN,
  STUDENT,
  STAFF,
} from "constants/ThemeConstant";
import utils from "utils";
import { useThemeSwitcher } from "react-css-theme-switcher";
import PageHeader from "components/layout-components/PageHeader";
import {
  getPrograms,
  setNavigationConfig,
  hideNotification,
  getStudentDashboardData,
  getStaffDashboardData,
  getStaffPastSessions,
  getStudentPastSessions,
} from "redux/actions";
import { ReadOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = (props) => {
  const {
    navCollapsed,
    navType,
    location,
    direction,
    getPrograms,
    programList,
    getStudentDashboardData,
    getStaffDashboardData,
    setNavigationConfig,
    notificationMessage,
    notificationDescription,
    notificationType,
    notificationVisible,
    hideNotification,
    children,
    userRole,
    getStaffPastSessions,
    pastSessions,
    getStudentPastSessions,
    pods,
  } = props;

  const [routeInfo, setRouteInfo] = useState(null);

  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() };
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() };
    }
    return { paddingLeft: getLayoutGutter() };
  };

  useEffect(() => {
    if (notificationVisible) {
      notification[notificationType]({
        message: notificationMessage,
        description: notificationDescription,
        onClose: hideNotification,
      });
    }
  }, [
    notificationDescription,
    notificationMessage,
    notificationType,
    notificationVisible,
  ]);

  useEffect(() => {
    let programs = [];
    if (userRole === ADMIN) {
      programs = programList;
    } else if (userRole === STAFF) {
      programs = pastSessions
        .map((pod) => pod.program)
        .filter(
          (program, index, self) =>
            index === self.findIndex((_program) => _program.id === program.id)
        );
    }

    const programMenuItems = programs.map((program) => ({
      id: program.id,
      key: `${userRole}-content-library-${program.id}`,
      path: `/${userRole}/content-library/${program.id}/${
        userRole === ADMIN ? "sessions" : "pods"
      }`,
      title: program.name,
      icon: "",
      breadcrumb: true,
      submenu: [],
    }));

    const contentLibrary = [
      {
        key: `${userRole}-content-library`,
        title: "Content Library",
        path: `/${userRole}/content-library`,
        icon: ReadOutlined,
        breadcrumb: true,
        submenu: programMenuItems,
      },
    ];

    let navigationConfig = [];

    if (userRole === ADMIN) {
      navigationConfig = [
        ...contentLibrary,
        ...podManagement,
        ...blackoutCalendar,
        ...studentManagement,
        ...attendanceManagement,
        ...staffManagement,
        ...adminProfile,
      ];
    } else if (userRole === STUDENT) {
      navigationConfig = [
        ...studentDashboard,
        ...studentSession,
        ...studentProfile,
      ];
    } else if (userRole === STAFF) {
      const tempStaff = staffDashboard[0];
      let subMenu = [];

      if (pods) {
        subMenu = pods.map((pod) => ({
          key: pod.id,
          path: `/staff/dashboard/${pod.id}`,
          title: pod.name,
          icon: "",
          breadcrumb: false,
          submenu: [],
        }));
      }

      navigationConfig = [
        {
          ...tempStaff,
          submenu: [...subMenu],
        },
        ...contentLibrary,
        ...staffProfile,
      ];
    }
    setNavigationConfig(navigationConfig);
    const currentRouteInfo = utils.getRouteInfo(
      navigationConfig,
      location.pathname
    );
    setRouteInfo(currentRouteInfo);
  }, [location.pathname, programList, pastSessions]);

  useEffect(() => {
    if (userRole === ADMIN) {
      getPrograms();
    } else if (userRole === STAFF) {
      getStaffPastSessions();
      getStaffDashboardData();
    } else if (userRole === STUDENT) {
      getStudentPastSessions();
      getStudentDashboardData();
    }
  }, []);

  return status === "loading" ? (
    <Loading cover="page" />
  ) : (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <TopNav routeInfo={routeInfo} /> : null}
      <Layout className="app-container">
        {isNavSide && !isMobile ? <SideNav routeInfo={routeInfo} /> : null}
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}>
            {routeInfo?.breadcrumb &&
            !routeInfo?.key.includes("content-library") ? (
              <PageHeader
                display={routeInfo?.breadcrumb}
                title={routeInfo?.title}
              />
            ) : null}
            <Content>{children}</Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

const mapStateToProps = ({
  theme,
  auth,
  program,
  common,
  staffPortal,
  profile,
}) => {
  const { userRole } = auth;
  const { programList } = program;
  const { pastSessions } = staffPortal;
  const {
    navigationConfig,
    notificationMessage,
    notificationDescription,
    notificationType,
    notificationVisible,
  } = common;
  const { navCollapsed, navType, locale } = theme;
  const { user } = profile;
  const { pods } = user;
  return {
    userRole,
    navCollapsed,
    navType,
    locale,
    programList,
    navigationConfig,
    notificationMessage,
    notificationDescription,
    notificationType,
    notificationVisible,
    pastSessions,
    pods,
  };
};

const mapDispatchToProps = {
  getPrograms,
  setNavigationConfig,
  hideNotification,
  getStudentDashboardData,
  getStudentPastSessions,
  getStaffDashboardData,
  getStaffPastSessions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AppLayout));
