import React from "react";
import { Card, Col, Image, Row } from "antd";
import S2FLogoTagline from "assets/svg/S2F_Logo_Tagline_EN.svg";
import AuthViews from "views/auth-views";

export const AuthLayout = () => (
  <div className="auth-container">
    <div className="h-100 auth-page">
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={16} sm={14} md={12} lg={7}>
            <Card>
              <div className="mt-2 mb-4">
                <div className="text-center">
                  <Image src={S2FLogoTagline} preview={false} width={300} />
                </div>
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={20} className="text-center">
                    <AuthViews />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  </div>
);

export default AuthLayout;
