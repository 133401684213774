import fetch from "auth/FetchInterceptor";

const SchoolService = {};

SchoolService.getSchoolData = function fn(params) {
  return fetch({
    url: "/api/admin/schools",
    method: "GET",
    params: { ...params },
  });
};

SchoolService.createNewSchool = function fn(payload) {
  return fetch({
    url: "/api/admin/schools",
    method: "POST",
    data: payload,
  });
};

export default SchoolService;
