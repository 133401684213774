import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import {
  GET_BADGES,
  GET_STUDENT_PAST_SESSIONS,
  GET_STUDENT_DASHBOARD_DATA,
  GET_STUDENT_SESSION_BY_ID,
  REMOVE_SUBMISSION,
  UPLOAD_SUBMISSION,
  GET_POD_LEADERBOARD,
} from "redux/constants";
import {
  removeSubmissionSuccess,
  setBadges,
  setCurrentStudentSession,
  setStudentPastSessions,
  setStudentDashboardData,
  uploadSubmissionSuccess,
  stopLoadingStudentPortal,
  showNotification,
  setPodLeaderboard,
} from "redux/actions";
import StudentPortalService from "services/StudentPortalService";
import { GENERIC_ERROR } from "constants/MessageConstant";

export function* fetchDashboardData() {
  try {
    const data = yield call(StudentPortalService.getStudentDashboardData);
    if (data) {
      yield put(setStudentDashboardData(data[0]));
    }
  } catch (err) {
    yield put(stopLoadingStudentPortal());
  }
}

export function* fetchBadges() {
  try {
    const data = yield call(StudentPortalService.getBadges);
    if (data) {
      yield put(setBadges(data));
    }
  } catch (err) {
    yield put(stopLoadingStudentPortal());
  }
}

export function* uploadSubmission({ payload, meta }) {
  const { sessionId, data, attachmentId } = payload;
  const { status } = data;
  const { onSuccess, onError } = meta;

  try {
    let endpoint = StudentPortalService.uploadSubmission;

    if (attachmentId) {
      endpoint = StudentPortalService.updateSubmission;
    }

    const attachment = yield call(endpoint, payload);

    if (attachment) {
      yield put(uploadSubmissionSuccess({ ...attachment, status, sessionId }));
      onSuccess?.();
    }

    const message =
      status === "draft"
        ? "Journal & Challenge is saved!"
        : "Journal & Challenge submitted successfully!";

    yield fetchDashboardData();
    yield put(
      showNotification({
        message,
        type: "success",
      })
    );
  } catch (err) {
    yield put(stopLoadingStudentPortal());
    onError?.();
    yield put(
      showNotification({
        message: "Failed to upload submission.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* removeSubmission({ payload }) {
  try {
    yield call(StudentPortalService.removeSubmission, payload);
    yield put(removeSubmissionSuccess(payload));
    yield put(
      showNotification({
        message: "Submission successfully removed!",
        type: "success",
      })
    );
  } catch (err) {
    yield put(stopLoadingStudentPortal());
    yield put(
      showNotification({
        message: "Failed to remove submission.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* fetchPastSessions() {
  try {
    const data = yield call(StudentPortalService.getStudentPastSessions);
    if (data) {
      yield put(setStudentPastSessions(data[0]));
    }
  } catch (err) {
    yield put(stopLoadingStudentPortal());
  }
}

export function* fetchStudentSessionById({ payload }) {
  try {
    const data = yield call(
      StudentPortalService.getStudentSessionById,
      payload
    );
    if (data) {
      yield put(setCurrentStudentSession(data));
    }
  } catch (err) {
    yield put(stopLoadingStudentPortal());
  }
}

export function* fetchPodLeaderboard({ payload }) {
  try {
    const data = yield call(StudentPortalService.getPodLeaderboard, payload);
    if (data) {
      yield put(setPodLeaderboard(data));
    }
  } catch (err) {
    yield console.log("Error", err);
  }
}

export function* watchFetchDashboardData() {
  yield takeEvery(GET_STUDENT_DASHBOARD_DATA, fetchDashboardData);
}

export function* watchFetchBadges() {
  yield takeEvery(GET_BADGES, fetchBadges);
}

export function* watchUploadSubmission() {
  yield takeEvery(UPLOAD_SUBMISSION, uploadSubmission);
}

export function* watchRemoveSubmission() {
  yield takeEvery(REMOVE_SUBMISSION, removeSubmission);
}

export function* watchFetchPastSessions() {
  yield takeEvery(GET_STUDENT_PAST_SESSIONS, fetchPastSessions);
}

export function* watchFetchStudentSessionById() {
  yield takeEvery(GET_STUDENT_SESSION_BY_ID, fetchStudentSessionById);
}

export function* watchFetchPodLeaderboard() {
  yield takeEvery(GET_POD_LEADERBOARD, fetchPodLeaderboard);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchDashboardData),
    fork(watchFetchBadges),
    fork(watchUploadSubmission),
    fork(watchRemoveSubmission),
    fork(watchFetchPastSessions),
    fork(watchFetchStudentSessionById),
    fork(watchFetchPodLeaderboard),
  ]);
}
