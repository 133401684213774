import fetch from "auth/FetchInterceptor";

const OrganizationConstantService = {};

OrganizationConstantService.getRegions = function fn() {
  return fetch({
    url: "/api/public/regions",
    method: "GET",
  });
};

export default OrganizationConstantService;
