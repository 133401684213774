export const AUTHENTICATE_ADMIN = "AUTHENTICATE_ADMIN";
export const AUTHENTICATE_ADMIN_SUCCESS = "AUTHENTICATE_ADMIN_SUCCESS";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS";
export const RESET = "RESET";
export const SIGNOUT = "SIGNOUT";
export const SHOW_AUTH_MESSAGE = "SHOW_AUTH_MESSAGE";
export const HIDE_AUTH_MESSAGE = "HIDE_AUTH_MESSAGE";
export const AUTH_TOKEN = "auth_token";
export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const LOGIN_AS_USER = "LOGIN_AS_USER";
export const LOGIN_AS_USER_SUCCESS = "LOGIN_AS_USER_SUCCESS";
