import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { setRegions } from "redux/actions/OrganizationConstants";
import { GET_REGIONS } from "redux/constants/OrganizationConstants";
import OrganizationConstantService from "services/OrganizationConstantService";

// eslint-disable-next-line import/prefer-default-export
export function* fetchRegions() {
  try {
    const data = yield call(OrganizationConstantService.getRegions);

    if (data && data.regions) {
      yield put(setRegions(data.regions));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchFetchRegions() {
  yield takeEvery(GET_REGIONS, fetchRegions);
}

export default function* rootSaga() {
  yield all([fork(watchFetchRegions)]);
}
