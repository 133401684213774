export const ROW_GUTTER = 16;
export const SIDE_NAV_WIDTH = 250;
export const SIDE_NAV_COLLAPSED_WIDTH = 80;
export const DRAWER_WIDTH_LG = 430;
export const DRAWER_WIDTH = 380;
export const DRAWER_WIDTH_SM = 330;
export const SIDE_NAV_LIGHT = "SIDE_NAV_LIGHT";
export const SIDE_NAV_DARK = "SIDE_NAV_DARK";
export const NAV_TYPE_SIDE = "SIDE";
export const NAV_TYPE_TOP = "TOP";
export const DIR_LTR = "ltr";
export const DIR_RTL = "rtl";
export const ADMIN = "admin";
export const STAFF = "staff";
export const STUDENT = "student";

export const THEME_COLORS = ["gray", "accent-blue", "apple-green"];
