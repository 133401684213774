import {
  GET_BADGES,
  GET_STUDENT_PAST_SESSIONS,
  GET_STUDENT_DASHBOARD_DATA,
  GET_STUDENT_SESSION_BY_ID,
  REMOVE_SUBMISSION,
  REMOVE_SUBMISSION_SUCCESS,
  SET_BADGES,
  SET_CURRENT_STUDENT_SESSION,
  SET_STUDENT_PAST_SESSIONS,
  SET_PAST_SUBMISSIONS,
  SET_STUDENT_DASHBOARD_DATA,
  UPLOAD_SUBMISSION,
  UPLOAD_SUBMISSION_SUCCESS,
  STOP_LOADING_STUDENT_PORTAL,
  GET_POD_LEADERBOARD,
  SET_POD_LEADERBOARD,
} from "redux/constants";

export const stopLoadingStudentPortal = (payload) => ({
  type: STOP_LOADING_STUDENT_PORTAL,
  payload,
});

export const getStudentDashboardData = (payload) => ({
  type: GET_STUDENT_DASHBOARD_DATA,
  payload,
});

export const setStudentDashboardData = (payload) => ({
  type: SET_STUDENT_DASHBOARD_DATA,
  payload,
});

export const getBadges = (payload) => ({
  type: GET_BADGES,
  payload,
});

export const setBadges = (payload) => ({
  type: SET_BADGES,
  payload,
});

export const uploadSubmission = ({ payload, meta }) => ({
  type: UPLOAD_SUBMISSION,
  payload,
  meta,
});

export const uploadSubmissionSuccess = (payload) => ({
  type: UPLOAD_SUBMISSION_SUCCESS,
  payload,
});

export const removeSubmission = (payload) => ({
  type: REMOVE_SUBMISSION,
  payload,
});

export const removeSubmissionSuccess = (payload) => ({
  type: REMOVE_SUBMISSION_SUCCESS,
  payload,
});

export const getStudentPastSessions = (payload) => ({
  type: GET_STUDENT_PAST_SESSIONS,
  payload,
});

export const setStudentPastSessions = (payload) => ({
  type: SET_STUDENT_PAST_SESSIONS,
  payload,
});

export const setPastSubmissions = (payload) => ({
  type: SET_PAST_SUBMISSIONS,
  payload,
});

export const getStudentSessionById = (payload) => ({
  type: GET_STUDENT_SESSION_BY_ID,
  payload,
});

export const setCurrentStudentSession = (payload) => ({
  type: SET_CURRENT_STUDENT_SESSION,
  payload,
});

export const getPodLeaderboard = (payload) => ({
  type: GET_POD_LEADERBOARD,
  payload,
});

export const setPodLeaderboard = (payload) => ({
  type: SET_POD_LEADERBOARD,
  payload,
});
