import {
  GET_SESSIONS,
  UPLOAD_ATTACHMENT_SUCCESS,
  SET_SESSIONS,
  SET_CURRENT_SESSION,
  REMOVE_ATTACHMENT_SUCCESS,
  GET_SESSION_BY_ID,
  CLEAR_ATTACHMENTS,
  ADD_ATTACHMENTS,
  CHANGE_STATUS_TAB,
  STOP_LOADING_SESSION,
  SET_SESSION_MATERIALS,
  REMOVE_SESSION_MATERIAL,
} from "redux/constants";

const initState = {
  loading: false,
  loadingAttachments: false,
  sessionsList: [],
  currentSession: null,
  sessionMaterials: [],
  attachments: {
    coach: [],
    studentWorksheet: [],
    studentHandout: [],
  },
  sessionTabStatus: "published",
};

const session = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STOP_LOADING_SESSION: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_SESSIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_SESSIONS: {
      const { sessionsList } = payload;
      return {
        ...state,
        sessionsList,
        loading: false,
      };
    }
    case GET_SESSION_BY_ID: {
      return {
        ...state,
        loadingAttachments: true,
        loading: true,
      };
    }
    case SET_CURRENT_SESSION: {
      return {
        ...state,
        currentSession: payload,
        loading: false,
      };
    }
    case SET_SESSION_MATERIALS: {
      return {
        ...state,
        sessionMaterials: payload,
      };
    }
    case REMOVE_SESSION_MATERIAL: {
      const { id, name } = payload;
      return {
        ...state,
        sessionMaterials: state.sessionMaterials.filter(
          (material) => !(material.id === id && material.name === name)
        ),
      };
    }
    case UPLOAD_ATTACHMENT_SUCCESS: {
      const { type: attachmentType } = payload;
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [attachmentType]: [...state.attachments[attachmentType], payload],
        },
      };
    }
    case REMOVE_ATTACHMENT_SUCCESS: {
      const { id, type: attachmentType } = payload;
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [attachmentType]: state.attachments[attachmentType].filter(
            (attachment) => attachment.id !== id
          ),
        },
      };
    }
    case CLEAR_ATTACHMENTS: {
      return {
        ...state,
        attachments: {
          coach: [],
          studentWorksheet: [],
          studentHandout: [],
        },
      };
    }
    case ADD_ATTACHMENTS: {
      return {
        ...state,
        attachments: payload,
        loadingAttachments: false,
      };
    }
    case CHANGE_STATUS_TAB: {
      return {
        ...state,
        sessionTabStatus: payload,
      };
    }
    default:
      return state;
  }
};

export default session;
