import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import {
  DRAWER_WIDTH,
  DRAWER_WIDTH_LG,
  DRAWER_WIDTH_SM,
} from "constants/ThemeConstant";

const DetailsDrawer = (props) => {
  const {
    label,
    maskClosable,
    handleClose,
    visible,
    children,
    footer,
    size = "md",
  } = props;

  const [drawerSize, setDrawerSize] = useState(DRAWER_WIDTH);

  useEffect(() => {
    switch (size) {
      case "lg":
        setDrawerSize(DRAWER_WIDTH_LG);
        break;
      case "sm":
        setDrawerSize(DRAWER_WIDTH_SM);
        break;
      default:
        break;
    }
  }, []);

  return (
    <Drawer
      title={label}
      placement="right"
      width={drawerSize}
      onClose={handleClose}
      visible={visible}
      closable={false}
      maskClosable={maskClosable}
      footer={footer}
    >
      {children ?? null}
    </Drawer>
  );
};

export default DetailsDrawer;
