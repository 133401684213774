import React, { useEffect, useState } from "react";
import { Avatar, Dropdown, Menu } from "antd";
import { connect } from "react-redux";
import {
  EditOutlined,
  LockOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { showAuthMessage, signOut } from "redux/actions";
import { ADMIN, STAFF } from "constants/ThemeConstant";

const NavProfile = ({
  user,
  userRole,
  impersonatorId,
  signOut,
  showAuthMessage,
}) => {
  const [menuItems, setMenuItems] = useState([]);

  const handleLogOut = () => {
    localStorage.clear();
    signOut();
    showAuthMessage({
      messageContent: "You have been signed out.",
      messageType: "info",
    });
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-body">
        <Menu>
          {menuItems.map((item) => (
            <Menu.Item key={item.title}>
              <a href={item.path}>
                <Icon className="mr-3" type={item.icon} />
                <span className="font-weight-normal">{item.title}</span>
              </a>
            </Menu.Item>
          ))}
          <Menu.Item key={menuItems.length + 1} onClick={handleLogOut}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Log Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  useEffect(() => {
    let menuItems = [];
    if (userRole === ADMIN || userRole === STAFF) {
      menuItems = [
        {
          title: "Edit Profile",
          icon: EditOutlined,
          path: `/${userRole}/settings/edit-profile`,
        },
        {
          title: "Change Password",
          icon: LockOutlined,
          path: `/${userRole}/settings/change-password`,
        },
      ];
    }
    setMenuItems(menuItems);
  }, [userRole]);

  return (
    <Dropdown
      placement="bottomRight"
      overlay={profileMenu}
      trigger={["click"]}
      className="cursor-pointer"
    >
      <div
        className="d-flex align-items-center p-2"
        style={{ backgroundColor: `${impersonatorId ? "#e55039" : "none"}` }}
      >
        <Avatar
          src={user?.profileImage}
          icon={<UserOutlined />}
          className="mr-2"
        />
        <h5 className="mt-2">
          {user?.firstName} {user?.lastName}
        </h5>
      </div>
    </Dropdown>
  );
};

const mapStateToProps = ({ auth, profile }) => {
  const { userRole, impersonatorId } = auth;
  const { user } = profile;
  return { user, userRole, impersonatorId };
};

const mapDispatchToProps = {
  showAuthMessage,
  signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile);
