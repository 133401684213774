import { ALL_REGIONS_TEXT } from "constants/AppConstant";
import {
  ADD_DATEPICKER,
  CLEAR_DATEPICKER_BLACKOUT_DATES,
  REMOVE_DATEPICKER,
  SET_CALENDAR_BY_DATE,
  SET_CALENDAR_BY_REGION,
  SET_DATEPICKER_BLACKOUT_DATES,
  SET_DATEPICKER_INDEX,
  SET_PAST_DATES,
  SET_REMOVING_DATE,
  SET_SAVING_DATE,
  SET_SELECTED_MONTH_YEAR,
  SET_SELECTED_REGION,
  SET_UPCOMING_DATES,
  STOP_LOADING_CALENDAR,
} from "redux/constants";

const initState = {
  loading: false,
  saving: false,
  removing: false,
  selectedMonthYear: {},
  selectedRegion: ALL_REGIONS_TEXT,
  generalParams: null,
  calendarView: null,
  pastListView: null,
  upcomingListView: null,
  datepickerIndex: 0,
  datepickerBlackoutDates: {},
};

const calendar = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STOP_LOADING_CALENDAR: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_SAVING_DATE: {
      return {
        ...state,
        saving: payload,
      };
    }
    case SET_REMOVING_DATE: {
      return {
        ...state,
        removing: payload,
      };
    }
    case SET_SELECTED_MONTH_YEAR: {
      return {
        ...state,
        loading: false,
        selectedMonthYear: payload,
      };
    }
    case SET_SELECTED_REGION: {
      return {
        ...state,
        loading: false,
        selectedRegion: payload,
      };
    }
    case SET_CALENDAR_BY_REGION: {
      return {
        ...state,
        loading: false,
        generalParams: payload.generalParams,
        calendarView: payload.calendarView,
        pastListView: payload.pastListView,
        upcomingListView: payload.upcomingListView,
      };
    }
    case SET_CALENDAR_BY_DATE: {
      return {
        ...state,
        loading: false,
        generalParams: payload.generalParams,
        calendarView: payload.calendarView,
      };
    }
    case SET_UPCOMING_DATES: {
      return {
        ...state,
        loading: false,
        upcomingListView: {
          metaData: payload.metaData,
          blackoutDates: [
            ...state.upcomingListView.blackoutDates,
            ...payload.blackoutDates,
          ],
        },
      };
    }
    case SET_PAST_DATES: {
      return {
        ...state,
        loading: false,
        pastListView: {
          metaData: payload.metaData,
          blackoutDates: [
            ...state.pastListView.blackoutDates,
            ...payload.blackoutDates,
          ],
        },
      };
    }
    case SET_DATEPICKER_INDEX: {
      return {
        ...state,
        datepickerIndex: payload,
      };
    }
    case ADD_DATEPICKER: {
      return {
        ...state,
        datepickerBlackoutDates: [
          ...state.datepickerBlackoutDates,
          {
            date: null,
            blackoutDates: [],
          },
        ],
      };
    }
    case REMOVE_DATEPICKER: {
      return {
        ...state,
        datepickerIndex: 0,
        datepickerBlackoutDates: state.datepickerBlackoutDates.filter(
          (_, index) => index !== payload
        ),
      };
    }
    case SET_DATEPICKER_BLACKOUT_DATES: {
      return {
        ...state,
        loading: false,
        datepickerBlackoutDates: {
          ...state.datepickerBlackoutDates,
          [payload.date]: [...payload.data.calendarView.blackoutDates],
        },
      };
    }
    case CLEAR_DATEPICKER_BLACKOUT_DATES: {
      return {
        ...state,
        datepickerIndex: 0,
        datepickerBlackoutDates: {},
      };
    }
    default:
      return state;
  }
};

export default calendar;
