import {
  CHANGE_PASSWORD,
  EDIT_PROFILE,
  GET_PROFILE,
  REMOVE_PHOTO,
  SET_PROFILE,
  SET_ROLES,
  UPLOAD_PHOTO,
  GET_AVATARS,
  SAVE_AVATAR,
  GET_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  MARK_NOTIFICATIONS_READ,
  DELETE_NOTIFICATION,
} from "redux/constants";

export const getProfile = (payload) => ({
  type: GET_PROFILE,
  payload,
});

export const setProfile = (payload) => ({
  type: SET_PROFILE,
  payload,
});

export const uploadPhoto = ({ payload, meta }) => ({
  type: UPLOAD_PHOTO,
  payload,
  meta,
});

export const removePhoto = (payload) => ({
  type: REMOVE_PHOTO,
  payload,
});

export const editProfile = ({ payload, meta }) => ({
  type: EDIT_PROFILE,
  payload,
  meta,
});

export const changePassword = ({ payload, meta }) => ({
  type: CHANGE_PASSWORD,
  payload,
  meta,
});

export const setRoles = (payload) => ({
  type: SET_ROLES,
  payload,
});

export const getAvatars = ({ payload, meta }) => ({
  type: GET_AVATARS,
  payload,
  meta,
});

export const saveAvatar = ({ payload }) => ({
  type: SAVE_AVATAR,
  payload,
});

export const getNotifications = (payload) => ({
  type: GET_NOTIFICATIONS,
  payload,
});

export const setNotifications = (payload) => ({
  type: SET_NOTIFICATIONS,
  payload,
});

export const markNotificationsRead = (payload) => ({
  type: MARK_NOTIFICATIONS_READ,
  payload,
});

export const deleteNotification = (payload) => ({
  type: DELETE_NOTIFICATION,
  payload,
});
