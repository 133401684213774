export const STOP_LOADING_STUDENT_PORTAL = "STOP_LOADING_STUDENT_PORTAL";
export const GET_STUDENT_DASHBOARD_DATA = "GET_STUDENT_DASHBOARD_DATA";
export const SET_STUDENT_DASHBOARD_DATA = "SET_STUDENT_DASHBOARD_DATA";
export const GET_BADGES = "GET_BADGES";
export const SET_BADGES = "SET_BADGES";
export const UPLOAD_SUBMISSION = "UPLOAD_SUBMISSION";
export const UPLOAD_SUBMISSION_SUCCESS = "UPLOAD_SUBMISSION_SUCCESS";
export const REMOVE_SUBMISSION = "REMOVE_SUBMISSION";
export const REMOVE_SUBMISSION_SUCCESS = "REMOVE_SUBMISSION_SUCCESS";
export const GET_STUDENT_PAST_SESSIONS = "GET_STUDENT_PAST_SESSIONS";
export const SET_STUDENT_PAST_SESSIONS = "SET_STUDENT_PAST_SESSIONS";
export const SET_PAST_SUBMISSIONS = "SET_PAST_SUBMISSIONS";
export const GET_STUDENT_SESSION_BY_ID = "GET_STUDENT_SESSION_BY_ID";
export const SET_CURRENT_STUDENT_SESSION = "SET_CURRENT_STUDENT_SESSION";
export const GET_POD_LEADERBOARD = "GET_POD_LEADERBOARD";
export const SET_POD_LEADERBOARD = "SET_POD_LEADERBOARD";
