import {
  GET_PROGRAM_DATA,
  SET_PROGRAM_DATA,
  STOP_LOADING_PROGRAM,
} from "redux/constants";

export const getPrograms = (payload) => ({
  type: GET_PROGRAM_DATA,
  payload,
});

export const setPrograms = (payload) => ({
  type: SET_PROGRAM_DATA,
  payload,
});

export const stopLoadingProgram = (payload) => ({
  type: STOP_LOADING_PROGRAM,
  payload,
});
