import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";

export const AdminViews = () => (
  <Suspense fallback={<Loading cover="content" />}>
    <Switch>
      <Route path="" component={lazy(() => import(`./pages`))} />
      <Redirect from="/" to="/admin/student-management" />
    </Switch>
  </Suspense>
);

export default React.memo(AdminViews);
