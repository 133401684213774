import {
  ADD_COACH_TOP_POD,
  ADD_STUDENT_TO_POD,
  ARCHIVE_POD,
  CREATE_POD,
  EDIT_POD,
  GET_POD_BY_ID,
  GET_POD_DATA,
  REMOVE_COACH_FROM_POD,
  REMOVE_STUDENT_FROM_POD,
  SET_CURRENT_POD,
  SET_POD_DATA,
  STOP_LOADING_POD,
  GET_POD_MESSAGE_BOARD,
  SET_POD_MESSAGE_BOARD,
  ADD_POST_TO_MESSAGE_BOARD,
  DELETE_POST_FROM_MESSAGE_BOARD,
  CHANGE_MESSAGEBOARD_PAGE,
  GET_NOTIFICATION_THREAD,
  SET_NOTIFICATION_THREAD,
  SET_NOTIFICATION_THREAD_LOADING,
} from "redux/constants";

export const stopLoadingPod = () => ({
  type: STOP_LOADING_POD,
});

export const getPodData = (payload) => ({
  type: GET_POD_DATA,
  payload,
});

export const setPodData = (payload) => ({
  type: SET_POD_DATA,
  payload,
});

export const createPod = (payload) => ({
  type: CREATE_POD,
  payload,
});

export const getPodById = (payload) => ({
  type: GET_POD_BY_ID,
  payload,
});

export const setCurrentPod = (payload) => ({
  type: SET_CURRENT_POD,
  payload,
});

export const editPod = (payload) => ({
  type: EDIT_POD,
  payload,
});

export const archivePod = (payload) => ({
  type: ARCHIVE_POD,
  payload,
});

export const addStudentToPod = (payload) => ({
  type: ADD_STUDENT_TO_POD,
  payload,
});

export const addCoachToPod = (payload) => ({
  type: ADD_COACH_TOP_POD,
  payload,
});

export const removeStudentFromPod = (payload) => ({
  type: REMOVE_STUDENT_FROM_POD,
  payload,
});

export const removeCoachFromPod = (payload) => ({
  type: REMOVE_COACH_FROM_POD,
  payload,
});

export const getPodMessageBoard = (payload) => ({
  type: GET_POD_MESSAGE_BOARD,
  payload,
});

export const setPodMessageBoard = (payload) => ({
  type: SET_POD_MESSAGE_BOARD,
  payload,
});

export const addPostToMessageBoard = (payload) => ({
  type: ADD_POST_TO_MESSAGE_BOARD,
  payload,
});

export const deletePostFromMessageBoard = (payload) => ({
  type: DELETE_POST_FROM_MESSAGE_BOARD,
  payload,
});

export const changeMessageboardPage = (payload) => ({
  type: CHANGE_MESSAGEBOARD_PAGE,
  payload,
});

export const getNotificationThread = (payload) => ({
  type: GET_NOTIFICATION_THREAD,
  payload,
});

export const setNotificationThreadLoading = (payload) => ({
  type: SET_NOTIFICATION_THREAD_LOADING,
  payload,
});

export const setNotificationThread = (payload) => ({
  type: SET_NOTIFICATION_THREAD,
  payload,
});
