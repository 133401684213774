import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import { GET_PROGRAM_DATA } from "redux/constants";
import { setPrograms, stopLoadingProgram } from "redux/actions";
import ProgramService from "services/ProgramService";

export function* fetchPrograms({ payload }) {
  try {
    const data = yield call(ProgramService.getPrograms, payload);
    if (data) {
      const { programs } = data;
      yield put(setPrograms({ programList: programs }));
    }
  } catch (err) {
    yield put(stopLoadingProgram());
  }
}

export function* watchFetchPrograms() {
  yield takeEvery(GET_PROGRAM_DATA, fetchPrograms);
}

export default function* rootSaga() {
  yield all([fork(watchFetchPrograms)]);
}
