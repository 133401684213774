import fetch from "auth/FetchInterceptor";

const ProgramService = {};

ProgramService.getPrograms = function fn() {
  return fetch({
    url: "/api/admin/programs",
    method: "GET",
    params: {
      page: 1,
      size: 10,
    },
  });
};

export default ProgramService;
