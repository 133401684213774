import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import StudentService from "services/StudentService";
import {
  ADD_STUDENT,
  DELETE_STUDENT,
  EDIT_STUDENT,
  GET_PASSPHRASE,
  GET_STUDENT_DATA,
  GET_STUDENT_ATTENDANCE,
  GET_ATTENDANCE_PROGRAM_FILTER,
  GET_ATTENDANCE_STUDENT_FILTER,
  GET_ATTENDANCE_POD_FILTER,
  GET_ATTENDANCE_CSV,
  ARCHIVE_STUDENTS,
  EXPORT_PARENTS,
  DELETE_PARENTS,
  EXPORT_STUDENT_DATA,
} from "redux/constants";
import {
  getParentData,
  getPassphrase,
  getStudentData,
  setPassphrase,
  setStudentData,
  showNotification,
  stopLoadingStudent,
  setStudentAttendance,
  setAttendanceProgramFilter,
  setAttendanceStudentFilter,
  setAttendancePodFilter,
  setArchiveStudents,
  setExportParents,
  setDeleteParents,
} from "redux/actions";
import { GENERIC_ERROR, SAVED_SUCCESS } from "constants/MessageConstant";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "constants/AppConstant";
import SchoolService from "services/SchoolService";
import EmergencyContactService from "services/EmergencyContactService";
import Utils from "utils";

export function* fetchStudentData({ payload }) {
  try {
    const data = yield call(StudentService.getStudentData, payload);
    if (data && data.students) {
      const { count, totalPages, currentPage, students } = data;
      yield put(
        setStudentData({
          count,
          totalPages,
          currentPage,
          studentList: students,
          pageSize: payload.size,
        })
      );

      if (students.length === 0) {
        yield put(setArchiveStudents(true));
      }
    }
  } catch (err) {
    yield put(stopLoadingStudent());
  }
}

export function* addStudent({ payload }) {
  try {
    // The following block of code that relates to the creation of a new school
    // can be removed once the back-end handles this in the create student flow.
    if (payload.createNewSchool) {
      const newSchool = {
        name: payload.school.name,
        region: payload.region,
        isCustomSchool: true,
      };

      const newSchoolId = yield call(SchoolService.createNewSchool, newSchool);

      payload.schoolId = newSchoolId.schoolId;
    }

    const student = yield call(StudentService.addStudent, payload);
    if (student) {
      yield put(
        showNotification({
          message: "New student added.",
          description: `You have successfully added ${student.firstName} ${student.lastName} to students.`,
          type: "success",
        })
      );
      yield put(
        getStudentData({
          page: DEFAULT_PAGE,
          size: DEFAULT_PAGE_SIZE,
          sortBy: ["updatedAt,DESC"],
        })
      );
      yield put(getParentData({ page: DEFAULT_PAGE, size: DEFAULT_PAGE_SIZE }));
      yield put(getPassphrase());
    }
  } catch (err) {
    yield put(stopLoadingStudent());
    yield put(
      showNotification({
        message: "Failed to add student.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* deleteStudent({ payload }) {
  try {
    yield call(StudentService.deleteStudent, payload);
    yield put(
      showNotification({
        message: "Student successfully deleted.",
        type: "success",
      })
    );
    yield put(
      getStudentData({
        page: DEFAULT_PAGE,
        size: DEFAULT_PAGE_SIZE,
        sortBy: ["updatedAt,DESC"],
      })
    );
  } catch (err) {
    yield put(stopLoadingStudent());
    yield put(
      showNotification({
        message: "Failed to delete student.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* editStudent({ payload }) {
  try {
    if (payload.values.createNewSchool) {
      const newSchool = {
        name: payload.values.school.name,
        region: payload.values.region,
        isCustomSchool: true,
      };

      const newSchoolId = yield call(SchoolService.createNewSchool, newSchool);

      payload.values.schoolId = newSchoolId.schoolId;
    }

    if (payload.values.createNewEmergencyContact) {
      const newContactId = yield call(
        EmergencyContactService.createEmergencyContact,
        payload.values.emergencyContact
      );

      payload.values.emergencyContactId = newContactId.emergencyContactId;
    }

    yield call(StudentService.editStudent, payload);
    yield put(
      showNotification({
        message: "Student info successfully updated.",
        description: SAVED_SUCCESS,
        type: "success",
      })
    );
    yield put(
      getStudentData({
        page: DEFAULT_PAGE,
        size: DEFAULT_PAGE_SIZE,
        sortBy: ["updatedAt,DESC"],
      })
    );
    yield put(getParentData({ page: DEFAULT_PAGE, size: DEFAULT_PAGE_SIZE }));
  } catch (err) {
    yield put(stopLoadingStudent());
    yield put(
      showNotification({
        message: "Failed to update student info.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* fetchPassphrase() {
  try {
    const data = yield call(StudentService.getPassphrase);
    if (data && data.passphrase) {
      const { passphrase } = data;
      yield put(setPassphrase(passphrase[0]));
    }
  } catch (err) {
    yield put(stopLoadingStudent());
  }
}

export function* fetchStudentAttendance({ payload }) {
  try {
    const data = yield call(StudentService.getStudentAttendance, payload);

    if (data) {
      yield put(setStudentAttendance(data));
    }
  } catch (err) {
    yield console.log("Error getting student attendance", err);
  }
}

export function* fetchAttendanceProgramFilter({ payload }) {
  try {
    const data = yield call(StudentService.getAttendanceProgramFilter, payload);

    if (data) {
      yield put(setAttendanceProgramFilter(data));
    }
  } catch (err) {
    yield console.log("Error getting student attendance", err);
  }
}

export function* fetchAttendanceStudentFilter({ payload }) {
  try {
    const data = yield call(StudentService.getAttendanceStudentFilter, payload);

    if (data) {
      yield put(setAttendanceStudentFilter(data));
    }
  } catch (err) {
    yield console.log("Error getting student attendance", err);
  }
}

export function* fetchAttendancePodFilter({ payload }) {
  try {
    const data = yield call(StudentService.getAttendancePodFilter, payload);

    if (data) {
      yield put(setAttendancePodFilter(data));
    }
  } catch (err) {
    yield console.log("Error getting student attendance", err);
  }
}

export function* fetchAttendanceCSV({ payload }) {
  try {
    const data = yield call(StudentService.getAttendanceCSV, payload);

    if (data) {
      const link = Utils.createDownloadLink(
        data,
        "text/csv;charset=utf-8;",
        "AttendanceExport"
      );
      link.click();
    }
  } catch (err) {
    yield console.log("Error getting student attendance csv", err);
  }
}

export function* triggerArchiveStudents() {
  try {
    const data = yield call(StudentService.triggerArchiveStudents);

    if (data) {
      yield put(setArchiveStudents(true));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export function* triggerExportParents() {
  try {
    const data = yield call(StudentService.triggerExportParents);

    if (data) {
      const link = Utils.createDownloadLink(
        data,
        "text/csv;charset=utf-8;",
        "ParentExport"
      );
      link.click();

      yield put(getParentData({ page: DEFAULT_PAGE, size: DEFAULT_PAGE_SIZE }));
      yield put(setExportParents(true));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export function* triggerDeleteParents() {
  try {
    const data = yield call(StudentService.triggerDeleteParents);

    if (data) {
      yield put(setDeleteParents(true));

      yield put(getParentData({ page: DEFAULT_PAGE, size: DEFAULT_PAGE_SIZE }));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export function* triggerExportStudentData() {
  try {
    const data = yield call(StudentService.triggerExportStudentData);

    if (data) {
      const link = Utils.createDownloadLink(
        data,
        "text/csv;charset=utf-8;",
        "StudentExport"
      );
      link.click();
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export function* watchFetchStudentData() {
  yield takeEvery(GET_STUDENT_DATA, fetchStudentData);
}

export function* watchAddStudent() {
  yield takeEvery(ADD_STUDENT, addStudent);
}

export function* watchDeleteStudent() {
  yield takeEvery(DELETE_STUDENT, deleteStudent);
}

export function* watchEditStudent() {
  yield takeEvery(EDIT_STUDENT, editStudent);
}

export function* watchFetchPassphrase() {
  yield takeEvery(GET_PASSPHRASE, fetchPassphrase);
}

export function* watchFetchStudentAttendance() {
  yield takeEvery(GET_STUDENT_ATTENDANCE, fetchStudentAttendance);
}

export function* watchFetchAttendanceProgramFilter() {
  yield takeEvery(GET_ATTENDANCE_PROGRAM_FILTER, fetchAttendanceProgramFilter);
}

export function* watchFetchAttendanceStudentFilter() {
  yield takeEvery(GET_ATTENDANCE_STUDENT_FILTER, fetchAttendanceStudentFilter);
}

export function* watchFetchAttendancePodFilter() {
  yield takeEvery(GET_ATTENDANCE_POD_FILTER, fetchAttendancePodFilter);
}

export function* watchFetchAttendanceCSV() {
  yield takeEvery(GET_ATTENDANCE_CSV, fetchAttendanceCSV);
}

export function* watchTriggerArchiveStudents() {
  yield takeEvery(ARCHIVE_STUDENTS, triggerArchiveStudents);
}

export function* watchTriggerExportParents() {
  yield takeEvery(EXPORT_PARENTS, triggerExportParents);
}

export function* watchTriggerDeleteParents() {
  yield takeEvery(DELETE_PARENTS, triggerDeleteParents);
}

export function* watchTriggerExportStudentData() {
  yield takeEvery(EXPORT_STUDENT_DATA, triggerExportStudentData);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchStudentData),
    fork(watchAddStudent),
    fork(watchDeleteStudent),
    fork(watchEditStudent),
    fork(watchFetchPassphrase),
    fork(watchFetchStudentAttendance),
    fork(watchFetchAttendanceProgramFilter),
    fork(watchFetchAttendanceStudentFilter),
    fork(watchFetchAttendancePodFilter),
    fork(watchFetchAttendanceCSV),
    fork(watchTriggerArchiveStudents),
    fork(watchTriggerExportParents),
    fork(watchTriggerDeleteParents),
    fork(watchTriggerExportStudentData),
  ]);
}
