import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "constants/AppConstant";
import {
  GET_PARENT_DATA,
  SET_CURRENT_PARENT,
  SET_PARENT_DATA,
  STOP_LOADING_PARENT,
} from "redux/constants";

const initState = {
  loading: false,
  count: 0,
  totalPages: 0,
  currentPage: DEFAULT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  parentList: [],
  currentParent: null,
};

const parent = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STOP_LOADING_PARENT: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_PARENT_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_PARENT_DATA: {
      const { parentList, count, totalPages, currentPage, pageSize } = payload;
      return {
        ...state,
        parentList,
        count,
        totalPages,
        currentPage,
        pageSize,
        loading: false,
      };
    }
    case SET_CURRENT_PARENT: {
      return {
        ...state,
        currentParent: {
          ...state.currentParent,
          ...payload,
        },
      };
    }
    default:
      return state;
  }
};

export default parent;
