import React from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";

const SuccessMessage = ({ message }) => (
  <div className="d-flex flex-column justify-content-center align-items-center">
    <CheckCircleTwoTone twoToneColor="#52C41A" />
    <p className="mt-3">{message}</p>
  </div>
);

export default SuccessMessage;
