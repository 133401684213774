import {
  GET_PROGRAM_DATA,
  SET_PROGRAM_DATA,
  STOP_LOADING_PROGRAM,
} from "redux/constants";

const initState = {
  loading: false,
  programList: [],
};

const program = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STOP_LOADING_PROGRAM: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_PROGRAM_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_PROGRAM_DATA: {
      const { programList } = payload;
      return {
        ...state,
        programList,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default program;
