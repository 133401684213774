const { SET_REGIONS } = require("redux/constants/OrganizationConstants");

const initState = {
  regions: [],
};

const organizationConstants = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_REGIONS:
      return {
        ...state,
        regions: payload,
      };
    default:
      return state;
  }
};

export default organizationConstants;
