import fetch from "auth/FetchInterceptor";

const CalendarService = {};

CalendarService.getCalendarData = function fn(params) {
  return fetch({
    url: "/api/admin/blackoutCalendar",
    method: "GET",
    params: { ...params },
  });
};

CalendarService.addDates = function fn({ regions, dates }) {
  return fetch({
    url: "/api/admin/blackoutCalendar",
    method: "POST",
    data: {
      regions,
      dates,
    },
  });
};

CalendarService.deleteDate = function fn(dateId) {
  return fetch({
    url: `/api/admin/blackoutCalendar/${dateId}`,
    method: "DELETE",
  });
};

CalendarService.updateDate = function fn({
  dateId,
  regions,
  startDate,
  endDate,
}) {
  return fetch({
    url: `/api/admin/blackoutCalendar/${dateId}`,
    method: "PUT",
    data: {
      regions,
      startDate,
      endDate,
    },
  });
};

export default CalendarService;
