import {
  END_SESSION,
  GET_STAFF_DASHBOARD_DATA,
  SET_STAFF_DASHBOARD_DATA,
  START_SESSION,
  SUBMIT_ATTENDANCE,
  GET_STAFF_PAST_SESSIONS,
  GET_STAFF_SESSION_BY_ID,
  MARK_SUBMISSION,
  SET_STAFF_CURRENT_SESSION_POD,
  SET_STAFF_PAST_SESSIONS,
  STOP_LOADING_STAFF_PORTAL,
  UPDATE_POD_LINK,
  SET_CURRENT_POD_LINK,
} from "redux/constants";

export const stopLoadingStaffPortal = (payload) => ({
  type: STOP_LOADING_STAFF_PORTAL,
  payload,
});

export const getStaffDashboardData = (payload) => ({
  type: GET_STAFF_DASHBOARD_DATA,
  payload,
});

export const setStaffDashboardData = (payload) => ({
  type: SET_STAFF_DASHBOARD_DATA,
  payload,
});

export const startSession = (payload) => ({
  type: START_SESSION,
  payload,
});

export const endSession = (payload) => ({
  type: END_SESSION,
  payload,
});

export const submitAttendance = (payload) => ({
  type: SUBMIT_ATTENDANCE,
  payload,
});

export const getStaffPastSessions = (payload) => ({
  type: GET_STAFF_PAST_SESSIONS,
  payload,
});

export const setStaffPastSessions = (payload) => ({
  type: SET_STAFF_PAST_SESSIONS,
  payload,
});

export const getStaffSessionById = (payload) => ({
  type: GET_STAFF_SESSION_BY_ID,
  payload,
});

export const setStaffCurrentSessionPod = (payload) => ({
  type: SET_STAFF_CURRENT_SESSION_POD,
  payload,
});

export const markSubmission = (payload) => ({
  type: MARK_SUBMISSION,
  payload,
});

export const updatePodLink = (payload) => ({
  type: UPDATE_POD_LINK,
  payload,
});

export const setCurrentPodLink = (payload) => ({
  type: SET_CURRENT_POD_LINK,
  payload,
});
