import fetch from "auth/FetchInterceptor";
import { STUDENT } from "constants/ThemeConstant";

const StudentPortalService = {};

StudentPortalService.getStudentDashboardData = function fn() {
  return fetch({
    url: "/api/students/dashboard",
    method: "GET",
  });
};

StudentPortalService.getBadges = function fn() {
  return fetch({
    url: "/api/students/badges",
    method: "GET",
  });
};

StudentPortalService.uploadSubmission = function fn({
  podId,
  sessionId,
  data,
}) {
  return fetch({
    url: `/api/students/pods/${podId}/sessions/${sessionId}/submission`,
    method: "POST",
    data,
  });
};

StudentPortalService.updateSubmission = function fn({
  podId,
  sessionId,
  attachmentId,
  data,
}) {
  return fetch({
    url: `/api/students/pods/${podId}/sessions/${sessionId}/attachment/${attachmentId}`,
    method: "PUT",
    data,
  });
};

StudentPortalService.removeSubmission = function fn({ podId, sessionId, id }) {
  return fetch({
    url: `/api/students/pods/${podId}/sessions/${sessionId}/submission/${id}`,
    method: "DELETE",
  });
};

StudentPortalService.getStudentPastSessions = function fn() {
  return fetch({
    url: "/api/students/sessions",
    method: "GET",
  });
};

StudentPortalService.getStudentSessionById = function fn({ podId, sessionId }) {
  return fetch({
    url: `/api/students/pods/${podId}/sessions/${sessionId}`,
    method: "GET",
  });
};

StudentPortalService.getPodLeaderboard = function fn({
  podId,
  start = 0,
  end = null,
  userRole,
}) {
  const user = userRole === STUDENT ? "students" : "staff";

  return fetch({
    url: `/api/${user}/pods/${podId}/leaderboard?start=${start}&end=${end}`,
    method: "GET",
  });
};

export default StudentPortalService;
