import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import {
  CHANGE_PASSWORD,
  EDIT_PROFILE,
  GET_PROFILE,
  REMOVE_PHOTO,
  UPLOAD_PHOTO,
  GET_AVATARS,
  SAVE_AVATAR,
  GET_NOTIFICATIONS,
  MARK_NOTIFICATIONS_READ,
  DELETE_NOTIFICATION,
} from "redux/constants";
import {
  getProfile,
  setProfile,
  showNotification,
  setNotifications,
} from "redux/actions";
import ProfileService from "services/ProfileService";
import { GENERIC_ERROR, SAVED_SUCCESS } from "constants/MessageConstant";

export function* fetchProfile({ payload }) {
  try {
    const data = yield call(ProfileService.getProfile, payload);
    if (data) {
      yield put(setProfile(data));
    }
  } catch (err) {
    console.log("ERROR: ", err);
  }
}

export function* uploadPhoto({ payload, meta }) {
  const { onSuccess, onError } = meta;
  const { userRole } = payload;
  try {
    yield call(ProfileService.uploadPhoto, payload);
    yield put(getProfile(userRole));
    onSuccess();
  } catch (err) {
    console.log("ERROR: ", err);
    onError();
  }
}

export function* removePhoto({ payload: userRole }) {
  try {
    yield call(ProfileService.removePhoto, userRole);
    yield put(getProfile(userRole));
  } catch (err) {
    console.log("ERROR: ", err);
  }
}

export function* getAvatars({ payload, meta }) {
  const { userRole } = payload;
  const { onSuccess } = meta;

  try {
    yield call(ProfileService.getAvatars, userRole, onSuccess);
  } catch (err) {
    console.log("ERROR: ", err);
  }
}

export function* editProfile({ payload, meta }) {
  const { userRole } = payload;
  const { setSubmitting, history } = meta;
  try {
    yield call(ProfileService.editProfile, payload);
    yield put(
      showNotification({
        message: "Profile successfully updated.",
        description: SAVED_SUCCESS,
        type: "success",
      })
    );
    yield put(getProfile(userRole));
    yield call(() =>
      history.push({
        pathname: `/${userRole}/profile-center`,
      })
    );
  } catch (err) {
    console.log("ERROR: ", err);
    yield put(
      showNotification({
        message: "Failed to update profile.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  } finally {
    setSubmitting(false);
  }
}

export function* changePassword({ payload, meta }) {
  try {
    yield call(ProfileService.changePassword, payload);
    yield put(
      showNotification({
        message: "Password successfully updated.",
        description: SAVED_SUCCESS,
        type: "success",
      })
    );
  } catch (err) {
    console.log("ERROR: ", err);
    yield put(
      showNotification({
        message: "Failed to update password.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  } finally {
    const { setSubmitting } = meta;
    setSubmitting(false);
  }
}

export function* saveAvatar({ payload }) {
  const { userRole, selectedAvatar } = payload;

  try {
    yield call(ProfileService.saveAvatar, selectedAvatar);
    yield put(
      showNotification({
        message: "Avatar successfully changed.",
        description: SAVED_SUCCESS,
        type: "success",
      })
    );
    yield put(getProfile(userRole));
  } catch (err) {
    console.log("Error: ", err);
    yield put(
      showNotification({
        message: "Failed to set avatar.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* getNotifications({ payload }) {
  try {
    const { notifications } = yield call(
      ProfileService.getNotifications,
      payload
    );
    yield put(setNotifications(notifications));
  } catch (err) {
    console.log("Error: ", err);

    yield put(
      showNotification({
        message: "Failed to get notifications.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* markNotificationsRead({ payload }) {
  try {
    yield call(ProfileService.markNotificationsRead, payload);

    const { userRole } = payload;
    const { notifications } = yield call(
      ProfileService.getNotifications,
      userRole
    );
    yield put(setNotifications(notifications));
  } catch (err) {
    console.log("Error: ", err);
    yield put(
      showNotification({
        message: "Failed to mark notifications as read.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* deleteNotification({ payload }) {
  try {
    yield call(ProfileService.deleteNotification, payload);

    const { notifications } = yield call(
      ProfileService.getNotifications,
      payload.userRole
    );
    yield put(setNotifications(notifications));
  } catch (err) {
    console.log("Error: ", err);
    yield put(
      showNotification({
        message: "Failed to delete notification.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* watchFetchProfile() {
  yield takeEvery(GET_PROFILE, fetchProfile);
}

export function* watchUploadPhoto() {
  yield takeEvery(UPLOAD_PHOTO, uploadPhoto);
}

export function* watchRemovePhoto() {
  yield takeEvery(REMOVE_PHOTO, removePhoto);
}

export function* watchEditProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
}

export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

export function* watchGetAvatars() {
  yield takeEvery(GET_AVATARS, getAvatars);
}

export function* watchSaveAvatar() {
  yield takeEvery(SAVE_AVATAR, saveAvatar);
}

export function* watchGetNotifications() {
  yield takeEvery(GET_NOTIFICATIONS, getNotifications);
}

export function* watchMarkNotificationsRead() {
  yield takeEvery(MARK_NOTIFICATIONS_READ, markNotificationsRead);
}

export function* watchDeleteNotification() {
  yield takeEvery(DELETE_NOTIFICATION, deleteNotification);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchProfile),
    fork(watchUploadPhoto),
    fork(watchRemovePhoto),
    fork(watchEditProfile),
    fork(watchChangePassword),
    fork(watchGetAvatars),
    fork(watchSaveAvatar),
    fork(watchGetNotifications),
    fork(watchMarkNotificationsRead),
    fork(watchDeleteNotification),
  ]);
}
