import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumb } from 'antd';

const BreadcrumbRoute = (props) => {
	const { location, navigationConfig } = props;

	const [pathSnippets, setPathSnippets] = useState([]);
	const [breadcrumbs, setBreadcrumbs] = useState({});

	useEffect(() => {
		const snippets = location.pathname.split('/').filter((i) => i);
		setPathSnippets(snippets);

		const breadcrumbData = {};
		const assignBreadcrumb = (obj) => {
			breadcrumbData[obj.path] = obj.title;
		};
	
		navigationConfig?.forEach((menuItem) => {
			assignBreadcrumb(menuItem);
			if (menuItem.submenu) {
				menuItem.submenu.forEach((submenuItem) => {
					assignBreadcrumb(submenuItem);
					if (submenuItem.submenu) {
						submenuItem.submenu.forEach((subSubmenuItem) => {
							assignBreadcrumb(subSubmenuItem);
						});
					}
				});
			}
		});
		setBreadcrumbs(breadcrumbData);
	}, [location.pathname, navigationConfig]);
  
  return (
		<Breadcrumb>
			{ pathSnippets.map((_, index) => {
				const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
				if (url !== "/admin") {
					return (
						breadcrumbs[url] ? (
							<Breadcrumb.Item key={url}>
								{ !url.endsWith("content-library")
									? <Link to={url}>{breadcrumbs[url]}</Link>
									: <span className="text-muted">{breadcrumbs[url]}</span>
								}
							</Breadcrumb.Item>
						) : null
					);
				} 
				return null;
			}) }
		</Breadcrumb>
  );
};

const mapStateToProps = ({ common }) => {
	const { navigationConfig } = common;
	return { navigationConfig };
};

export default withRouter(connect(mapStateToProps)(BreadcrumbRoute));
