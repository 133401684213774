import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import {
  CHANGE_SESSION_STATUS,
  CREATE_SESSION,
  DELETE_SESSION,
  EDIT_SESSION,
  GET_SESSIONS,
  GET_SESSION_BY_ID,
  REMOVE_ATTACHMENT,
  REMOVE_LINK,
  UPLOAD_ATTACHMENT,
} from "redux/constants";
import {
  removeAttachmentSuccess,
  setSessions,
  setCurrentSession,
  showNotification,
  uploadAttachmentSuccess,
  changeStatusTab,
  stopLoadingSession,
  removeSessionMaterial,
} from "redux/actions";
import SessionService from "services/SessionService";
import { GENERIC_ERROR, SAVED_SUCCESS } from "constants/MessageConstant";

export function* fetchSessions({ payload }) {
  try {
    const data = yield call(SessionService.getSessions, payload);
    if (data) {
      const { rows } = data;
      yield put(setSessions({ sessionsList: rows }));
    }
  } catch (err) {
    yield put(stopLoadingSession());
  }
}

export function* fetchSessionById({ payload }) {
  try {
    const data = yield call(SessionService.getSessionById, payload);
    if (data) {
      yield put(setCurrentSession(data));
    }
  } catch (err) {
    yield put(stopLoadingSession());
  }
}

export function* uploadAttachment({ payload, meta }) {
  const { onSuccess, onError, onProgress } = meta;
  try {
    const attachment = yield call(SessionService.uploadAttachment, {
      payload,
      onProgress,
    });
    if (attachment) {
      yield put(uploadAttachmentSuccess(attachment));
      onSuccess();
    }
  } catch (err) {
    yield put(stopLoadingSession());
    onError();
  }
}

export function* removeAttachment({ payload, meta }) {
  const { id } = payload;
  const { removingFromForm, onSuccess, onError } = meta;
  try {
    yield call(SessionService.removeAttachment, id);
    yield put(removeAttachmentSuccess(payload));
    yield put(removeSessionMaterial(payload));
    if (removingFromForm) onSuccess();
  } catch (err) {
    yield put(stopLoadingSession());
    if (removingFromForm) onError();
  }
}

export function* createSession({ payload, meta }) {
  try {
    const data = yield call(SessionService.createSession, payload);
    if (data) {
      if (data.status === "published") {
        yield put(
          showNotification({
            message: "Session successfully published!",
            description: `You have successfully published session ${data.name}. Coaches and students can access its content library based on their pod.`,
            type: "success",
          })
        );
      } else if (data.status === "drafts") {
        yield put(
          showNotification({
            message: "Session successfully saved as draft.",
            description: `You have successfully saved session ${data.name} as a draft. You can edit the session anytime.`,
            type: "success",
          })
        );
      }
      const { history } = meta;
      yield call(() =>
        history.push({
          pathname: `/admin/content-library/${data.programId}/sessions/${data.id}`,
        })
      );
    }
  } catch (err) {
    yield put(stopLoadingSession());
    yield put(
      showNotification({
        message: "Failed to create new session.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* editSession({ payload, meta }) {
  try {
    const data = yield call(SessionService.editSession, payload);
    if (data) {
      if (data.status === "published") {
        yield put(
          showNotification({
            message: "Session successfully published.",
            description: `You have successfully published session ${data.name}. Coaches and students can access its content library based on their pod.`,
            type: "success",
          })
        );
      } else if (data.status === "drafts") {
        yield put(
          showNotification({
            message: "Session info successfully updated.",
            description: SAVED_SUCCESS,
            type: "success",
          })
        );
      }
    }
    const { programId, sessionId } = payload;
    const { history } = meta;
    yield call(() =>
      history.push({
        pathname: `/admin/content-library/${programId}/sessions/${sessionId}`,
      })
    );
  } catch (err) {
    yield put(stopLoadingSession());
    yield put(
      showNotification({
        message: "Failed to update session info.",
        description: GENERIC_ERROR,
        type: "success",
      })
    );
  }
}

export function* removeLink({ payload }) {
  try {
    yield call(SessionService.removeLink, payload);
    yield put(removeSessionMaterial(payload));
  } catch (err) {
    console.log("ERROR: ", err);
  }
}

export function* changeSessionStatus({ payload, meta }) {
  try {
    const data = yield call(SessionService.editSession, payload);
    const {
      programId,
      values: { status },
    } = payload;
    const { history } = meta;
    if (data) {
      yield put(
        showNotification({
          message: `Session status successfully changed to ${status}.`,
          type: "success",
        })
      );
    }
    yield put(changeStatusTab(status));
    yield call(() =>
      history.push({
        pathname: `/admin/content-library/${programId}/sessions`,
      })
    );
  } catch (err) {
    yield put(stopLoadingSession());
    yield put(
      showNotification({
        message: "Failed to change session status.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* deleteSession({ payload, meta }) {
  try {
    const { programId } = payload;
    const { history } = meta;
    yield call(SessionService.deleteSession, payload);
    yield put(
      showNotification({
        message: "Session successfully deleted.",
        type: "success",
      })
    );
    yield call(() =>
      history.push({
        pathname: `/admin/content-library/${programId}/sessions`,
      })
    );
  } catch (err) {
    yield put(stopLoadingSession());
    yield put(
      showNotification({
        message: "Failed to delete session.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* watchFetchSessions() {
  yield takeEvery(GET_SESSIONS, fetchSessions);
}

export function* watchFetchSessionById() {
  yield takeEvery(GET_SESSION_BY_ID, fetchSessionById);
}

export function* watchUploadAttachment() {
  yield takeEvery(UPLOAD_ATTACHMENT, uploadAttachment);
}

export function* watchRemoveAttachment() {
  yield takeEvery(REMOVE_ATTACHMENT, removeAttachment);
}

export function* watchCreateSession() {
  yield takeEvery(CREATE_SESSION, createSession);
}

export function* watchEditSession() {
  yield takeEvery(EDIT_SESSION, editSession);
}

export function* watchRemoveLink() {
  yield takeEvery(REMOVE_LINK, removeLink);
}

export function* watchChangeSessionStatus() {
  yield takeEvery(CHANGE_SESSION_STATUS, changeSessionStatus);
}

export function* watchDeleteSession() {
  yield takeEvery(DELETE_SESSION, deleteSession);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchSessions),
    fork(watchFetchSessionById),
    fork(watchUploadAttachment),
    fork(watchRemoveAttachment),
    fork(watchCreateSession),
    fork(watchEditSession),
    fork(watchRemoveLink),
    fork(watchChangeSessionStatus),
    fork(watchDeleteSession),
  ]);
}
