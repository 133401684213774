import fetch from "auth/FetchInterceptor";

const SessionService = {};

SessionService.getSessions = function fn(id) {
  return fetch({
    url: `/api/admin/programs/${id}/sessions`,
    method: "GET",
  });
};

SessionService.getSessionById = function fn({ programId, sessionId }) {
  return fetch({
    url: `/api/admin/programs/${programId}/sessions/${sessionId}`,
    method: "GET",
  });
};

SessionService.uploadAttachment = function fn({ payload, onProgress }) {
  return fetch({
    url: "/api/admin/attachment/upload",
    method: "POST",
    data: payload,
    onUploadProgress: (event) =>
      onProgress({ percent: (event.loaded / event.total) * 100 }),
  });
};

SessionService.removeAttachment = function fn(id) {
  return fetch({
    url: `/api/admin/attachment/${id}`,
    method: "DELETE",
  });
};

SessionService.createSession = function fn({ id, values }) {
  return fetch({
    url: `/api/admin/programs/${id}/sessions`,
    method: "POST",
    data: values,
  });
};

SessionService.editSession = function fn({ programId, sessionId, values }) {
  return fetch({
    url: `/api/admin/programs/${programId}/sessions/${sessionId}`,
    method: "PUT",
    data: values,
  });
};

SessionService.removeLink = function fn({ programId, sessionId, id }) {
  return fetch({
    url: `/api/admin/programs/${programId}/sessions/${sessionId}/links/${id}`,
    method: "DELETE",
  });
};

SessionService.deleteSession = function fn({ programId, sessionId }) {
  return fetch({
    url: `/api/admin/programs/${programId}/sessions/${sessionId}`,
    method: "DELETE",
  });
};

export default SessionService;
