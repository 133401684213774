import fetch from "auth/FetchInterceptor";

const ParentService = {};

ParentService.getParentData = function fn(params) {
  return fetch({
    url: "/api/admin/parents",
    method: "GET",
    params: { ...params },
  });
};

ParentService.editParent = function fn({ id, values }) {
  return fetch({
    url: `/api/admin/parents/${id}`,
    method: "PUT",
    data: values,
  });
};

export default ParentService;
