import fetch from "auth/FetchInterceptor";

const AuthService = {};

AuthService.loginAdmin = function fn(data) {
  const { email, password } = data;
  return fetch({
    url: "/api/admin/authenticate",
    method: "POST",
    data: {
      username: email,
      password,
    },
  });
};

AuthService.loginUser = function fn({ values, role }) {
  return fetch({
    url: `/api/authenticate/${role}`,
    method: "POST",
    data: {
      username: values.email || values.name,
      password: values.password,
    },
  });
};

AuthService.verifyAccount = function fn(data) {
  return fetch({
    url: "/api/account/verify",
    method: "POST",
    data,
  });
};

AuthService.forgotPassword = function fn(data) {
  return fetch({
    url: "/api/account/forgotPassword",
    method: "POST",
    data,
  });
};

AuthService.resetPassword = function fn(data) {
  return fetch({
    url: "/api/account/resetPassword",
    method: "POST",
    data,
  });
};

AuthService.loginAsUser = function fn(data) {
  return fetch({
    url: "/api/admin/users/loginAsUser",
    method: "POST",
    data,
  });
};

export default AuthService;
