import React from "react";
import { Form, Button, Input, Avatar, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

const { TextArea } = Input;

const Editor = ({
  submitting = false,
  handleTextAreaChange,
  newPost,
  addComment,
  user,
}) => (
  <div className="message-editor">
    <Form.Item className="d-flex align-items-start">
      <Avatar src={user.profileImage} icon={<UserOutlined />} size="small" />

      <Space direction="vertical" size="large">
        <TextArea rows={4} onChange={handleTextAreaChange} value={newPost} />
        <Button
          htmlType="submit"
          loading={submitting}
          onClick={addComment}
          type="primary"
          disabled={newPost.length === 0}
        >
          Add Comment
        </Button>
      </Space>
    </Form.Item>
  </div>
);

const mapStateToProps = ({ profile }) => {
  const { user } = profile;

  return {
    user,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
