import {
  DashboardOutlined,
  ProjectOutlined,
  SmileOutlined,
  TeamOutlined,
  UserOutlined,
  ReadOutlined,
  CheckCircleOutlined,
  CalendarOutlined,
} from "@ant-design/icons";

export const podManagement = [
  {
    key: "pod-management",
    path: "/admin/pod-management",
    title: "Pod Management",
    icon: ProjectOutlined,
    breadcrumb: true,
    submenu: [],
  },
];

export const blackoutCalendar = [
  {
    key: "blackout-calendar",
    path: "/admin/blackout-calendar",
    title: "Blackout Calendar",
    icon: CalendarOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

export const studentManagement = [
  {
    key: "student-management",
    path: "/admin/student-management",
    title: "Student Management",
    icon: SmileOutlined,
    breadcrumb: true,
    submenu: [],
  },
];

export const staffManagement = [
  {
    key: "staff-management",
    path: "/admin/staff-management",
    title: "Staff Management",
    icon: TeamOutlined,
    breadcrumb: true,
    submenu: [],
  },
];

export const attendanceManagement = [
  {
    key: "attendance-management",
    path: "/admin/attendance-management",
    title: "Attendance Management",
    icon: CheckCircleOutlined,
    breadcrumb: true,
    submenu: [],
  },
];

export const adminProfile = [
  {
    key: "profile",
    title: "My Profile",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "profile-center",
        path: "/admin/profile-center",
        title: "Profile Center",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "settings",
        path: "/admin/settings",
        title: "Settings",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

export const studentDashboard = [
  {
    key: "dashboard",
    path: "/student/dashboard",
    title: "Dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

export const studentSession = [
  {
    key: "sessions",
    path: "/student/sessions",
    title: "Sessions",
    icon: ReadOutlined,
    breadcrumb: true,
    submenu: [],
  },
];

export const studentProfile = [
  {
    key: "profile",
    path: "/student/profile",
    title: "My Profile",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

export const staffDashboard = [
  {
    key: "dashboard",
    path: "/staff/dashboard",
    title: "Dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

export const staffProfile = [
  {
    key: "profile",
    title: "My Profile",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "profile-center",
        path: "/staff/profile-center",
        title: "Profile Center",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "settings",
        path: "/staff/settings",
        title: "Settings",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];
