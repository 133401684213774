import {
  GET_STAFF_DASHBOARD_DATA,
  SET_STAFF_DASHBOARD_DATA,
  GET_STAFF_PAST_SESSIONS,
  GET_STAFF_SESSION_BY_ID,
  SET_STAFF_CURRENT_SESSION_POD,
  SET_STAFF_PAST_SESSIONS,
  STOP_LOADING_STAFF_PORTAL,
  SET_CURRENT_POD_LINK,
} from "redux/constants";

const initState = {
  dashboard: null,
  loading: false,
  pastSessions: [],
  currentSessionPod: null,
  currentPodLink: "",
};

const staffPortal = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STOP_LOADING_STAFF_PORTAL: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_STAFF_DASHBOARD_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_STAFF_DASHBOARD_DATA: {
      return {
        ...state,
        dashboard: payload,
        loading: false,
      };
    }
    case GET_STAFF_PAST_SESSIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_STAFF_PAST_SESSIONS: {
      return {
        ...state,
        pastSessions: payload,
        loading: false,
      };
    }
    case GET_STAFF_SESSION_BY_ID: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_STAFF_CURRENT_SESSION_POD: {
      return {
        ...state,
        currentSessionPod: payload,
        loading: false,
      };
    }
    case SET_CURRENT_POD_LINK: {
      return {
        ...state,
        currentPodLink: payload,
      };
    }
    default:
      return state;
  }
};

export default staffPortal;
