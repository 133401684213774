import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const LoadingSpinner = () => (
  <div className="mt-4 text-center">
    <Spin indicator={<LoadingOutlined className="font-size-xxl" spin />} />
  </div>
);

export default LoadingSpinner;
