import {
  GET_SCHOOL_DATA,
  SET_SCHOOL_DATA,
  STOP_LOADING_SCHOOL,
} from "redux/constants";

export const getSchoolData = (payload) => ({
  type: GET_SCHOOL_DATA,
  payload,
});

export const setSchoolData = (payload) => ({
  type: SET_SCHOOL_DATA,
  payload,
});

export const stopLoadingSchool = (payload) => ({
  type: STOP_LOADING_SCHOOL,
  payload,
});
