import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "components/shared-components/Loading";

const AuthViews = () => (
  <Suspense fallback={<Loading cover="page" />}>
    <Switch>
      <Route
        path="/:role/login"
        component={lazy(() => import(`./authentication/login`))}
      />
      <Route
        exact
        path="/forgotpassword"
        component={lazy(() => import(`./authentication/forgot-password`))}
      />
      <Route
        exact
        path="/forgotpasswordsuccess"
        component={lazy(() =>
          import(`./authentication/forgot-password-success`)
        )}
      />
      <Route
        exact
        path="/verify"
        component={lazy(() => import(`./authentication/update-password`))}
      />
      <Route
        exact
        path="/verifysuccess"
        component={lazy(() =>
          import(`./authentication/update-password-success`)
        )}
      />
      <Route
        exact
        path="/resetpassword"
        component={lazy(() => import(`./authentication/update-password`))}
      />
      <Route
        exact
        path="/resetpasswordsuccess"
        component={lazy(() =>
          import(`./authentication/update-password-success`)
        )}
      />
    </Switch>
    <Route
      exact
      path="/student/signup"
      component={lazy(() => import(`./authentication/signup`))}
    />
    <Route
      exact
      path="/student/signup/success"
      component={lazy(() => import(`./authentication/signup-success`))}
    />
  </Suspense>
);

export default AuthViews;
