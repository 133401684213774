import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import { setSchools, setSignupSuccess, showNotification } from "redux/actions";
import { GET_SCHOOLS, SUBMIT_SIGNUP } from "redux/constants";
import SignupService from "services/SignupService";

export function* fetchSchools() {
  try {
    const data = yield call(SignupService.getSchools);
    yield put(setSchools(data));
  } catch (err) {
    console.log("Error", err);
  }
}

export function* submitSignupForm({ payload }) {
  try {
    yield call(SignupService.submitSignupForm, payload);

    yield put(
      showNotification({
        message: "You have successfully completed the registration.",
        type: "success",
      })
    );
    yield put(
      setSignupSuccess({
        attemptedFormSubmit: true,
        isSuccessful: true,
        message: "Signup successful!",
      })
    );
  } catch (err) {
    console.log("Error", err);

    yield put(
      setSignupSuccess({
        attemptedFormSubmit: true,
        isSuccessful: false,
        message: err,
      })
    );
  }
}

export function* watchFetchSchools() {
  yield takeEvery(GET_SCHOOLS, fetchSchools);
}

export function* watchSubmitSignupForm() {
  yield takeEvery(SUBMIT_SIGNUP, submitSignupForm);
}

export default function* rootSaga() {
  yield all([fork(watchFetchSchools), fork(watchSubmitSignupForm)]);
}
