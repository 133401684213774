import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import SchoolService from "services/SchoolService";
import { GET_SCHOOL_DATA } from "redux/constants";
import { setSchoolData, stopLoadingSchool } from "redux/actions";

export function* fetchSchoolData({ payload }) {
  try {
    const data = yield call(SchoolService.getSchoolData, payload);
    if (data && data.schools) {
      const { count, totalPages, currentPage, schools } = data;
      yield put(
        setSchoolData({
          count,
          totalPages,
          currentPage,
          schoolList: schools,
          pageSize: payload.size,
        })
      );
    }
  } catch (err) {
    yield put(stopLoadingSchool());
  }
}

export function* watchFetchSchoolData() {
  yield takeEvery(GET_SCHOOL_DATA, fetchSchoolData);
}

export default function* rootSaga() {
  yield all([fork(watchFetchSchoolData)]);
}
