import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Layout,
  Dropdown,
  Button,
  Badge,
  Space,
  Divider,
  List,
  Typography,
} from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import {
  markNotificationsRead,
  deleteNotification,
  getPodMessageBoard,
  getNotifications,
} from "redux/actions";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
  STUDENT,
} from "constants/ThemeConstant";
import InfiniteScroll from "react-infinite-scroll-component";
import utils from "utils";
import MessageBoardDrawer from "components/custom-components/DetailsDrawer/MessageBoardDrawer";
import useBoolean from "hooks/useBoolean";
import useClickOutsideElement from "hooks/useClickOutsideElement";
import NavProfile from "./NavProfile";
import Logo from "./Logo";

const { Header } = Layout;
const { Paragraph } = Typography;

const dividerStyle = {
  borderColor: "#BFBFBF",
};

const SCROLLABLE_DIV = "scrollableDiv";

export const HeaderNav = (props) => {
  const {
    userRole,
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    notifications,
    deleteNotification,
    markNotificationsRead,
    podId,
    currentPage,
    pageSize,
    getPodMessageBoard,
  } = props;
  const notificationRef = useRef(null);
  const [notificationNums, setNotificationNums] = useState(7);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [messageDrawerVisible, setMessageDrawerVisible] = useBoolean(false);
  const [notificationDropdownVisible, setNotificationDropdownVisible] =
    useBoolean(false);
  const [notificationsBadge, setNotificationsBadge] = useState(null);

  const getBoard = () => {
    if (podId && userRole === STUDENT) {
      getPodMessageBoard({
        podId,
        userRole,
        currentPage,
        size: pageSize,
      });
    }
  };

  useClickOutsideElement(notificationRef, setNotificationDropdownVisible.off);

  useEffect(() => {
    getBoard();
  }, [currentPage, podId, pageSize]);

  useEffect(() => {
    if (notifications) {
      const count = notifications?.filter((n) => n.readAt === null).length;
      setNotificationsBadge(count);
    }
  }, [notifications]);

  const handleToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    }
    return `${SIDE_NAV_WIDTH}px`;
  };

  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification.threadId);
    markNotificationsRead({
      notificationIds: [notification.id],
      updateAll: false,
      userRole,
    });
    setNotificationDropdownVisible.off();
    setMessageDrawerVisible.on();
  };

  const handleClearNotificationsClick = () => {
    deleteNotification({
      notificationIds: [],
      deleteAll: true,
      userRole,
    });
  };

  const loadMoreData = () => {
    setNotificationNums(notificationNums + 3);
  };

  const inifiteScrollNotifications = () => (
    <div id={SCROLLABLE_DIV} ref={notificationRef}>
      <InfiniteScroll
        dataLength={notifications?.length}
        next={loadMoreData}
        hasMore={notificationNums < notifications?.length}
        endMessage={
          <Divider plain style={dividerStyle}>
            You&apos;re all caught up!
          </Divider>
        }
        scrollableTarget={SCROLLABLE_DIV}
      >
        <List
          dataSource={notifications}
          header={
            <div className="notification-header d-flex justify-content-between align-items-center">
              <span>Notifications</span>
              <span>
                <Button
                  type="link"
                  onClick={handleClearNotificationsClick}
                  disabled={!(notifications?.length > 0)}
                >
                  Clear
                </Button>
              </span>
            </div>
          }
          renderItem={(item) => (
            <List.Item
              className={item.id !== "Header" && "notification"}
              onClick={() => handleNotificationClick(item)}
              key={item.id}
            >
              <Space align="start" size={16} key={item.id}>
                <div className="new-notification">
                  {!item.readAt && <div className="new-notification__circle" />}
                </div>
                <div className="notification-body">
                  <div className="notification-title">{item.title}</div>
                  <Paragraph
                    className="notification-content"
                    ellipsis={{ rows: 2 }}
                  >
                    {item.content}
                  </Paragraph>
                  <div className="notification-timestamp">
                    {utils.formatNotificationDate(item.createdAt)}
                  </div>
                </div>
              </Space>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );

  const onDrawerClose = () => {
    setSelectedNotification(null);
    setMessageDrawerVisible.off();
  };

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className={`nav-left ${userRole}-nav-left`}>
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={handleToggle}
                  onKeyPress={handleToggle}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
            </ul>
          </div>
          <div className="nav-right align-items-center">
            {notifications && (
              <Dropdown
                overlay={inifiteScrollNotifications}
                trigger={["click"]}
                overlayClassName="nav-notifications"
                arrow
                visible={notificationDropdownVisible}
              >
                <Button
                  type="text"
                  onClick={setNotificationDropdownVisible.toggle}
                >
                  <Badge count={notificationsBadge}>
                    <BellOutlined style={{ fontSize: "24px" }} />
                  </Badge>
                </Button>
              </Dropdown>
            )}
            <NavProfile />
          </div>
        </div>
      </div>

      <MessageBoardDrawer
        isVisible={messageDrawerVisible}
        onClose={onDrawerClose}
        userRole={userRole}
        messageId={selectedNotification}
        currentPage={currentPage}
      />
    </Header>
  );
};

const mapStateToProps = ({ auth, theme, profile, pod }) => {
  const { userRole } = auth;
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme } =
    theme;
  const { notifications, pods } = profile.user;
  const podId = userRole === STUDENT ? pods?.[0]?.id : null;
  const { currentPage, pageSize } = pod;

  return {
    userRole,
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    notifications,
    podId,
    currentPage,
    pageSize,
  };
};

const mapDispatchToProps = {
  markNotificationsRead,
  toggleCollapsedNav,
  onMobileNavToggle,
  deleteNotification,
  getPodMessageBoard,
  getNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);
