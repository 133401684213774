import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import { WEB_SOCKET_URL } from "constants/ApiConstant";
import { Provider, connect } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { getNotifications, getRegions } from "redux/actions";

import { THEME_CONFIG } from "./configs/AppConfig";
import Views from "./views";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App(props) {
  const { store, persistor, token, getNotifications, getRegions, userRole } =
    props;

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(WEB_SOCKET_URL, {
      query: { token },
      transports: ["websocket"],
    });

    setSocket(newSocket);

    return () => newSocket.close();
  }, [setSocket, token]);

  useEffect(() => {
    if (socket) {
      socket.on("newNotification", () => {
        getNotifications(userRole);
      });
    }
  }, [socket]);

  useEffect(() => {
    getRegions();
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Router>
              <Switch>
                <Route path="/" component={Views} />
              </Switch>
            </Router>
          </ThemeSwitcherProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

const mapStateToProps = ({ auth }) => {
  const { token, userRole } = auth;

  return {
    userRole,
    token,
  };
};

const mapDispatchToProps = { getNotifications, getRegions };

export default connect(mapStateToProps, mapDispatchToProps)(App);
