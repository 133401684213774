import fetch from "auth/FetchInterceptor";

const StaffService = {};

StaffService.getStaffData = function fn(params) {
  return fetch({
    url: "/api/admin/users",
    method: "GET",
    params: { ...params },
  });
};

StaffService.addStaff = function fn(staff) {
  return fetch({
    url: "/api/admin/users/register",
    method: "POST",
    data: staff,
  });
};

StaffService.editStaff = function fn({ id, values }) {
  return fetch({
    url: `/api/admin/users/${id}`,
    method: "PUT",
    data: values,
  });
};

StaffService.deleteStaff = function fn(id) {
  return fetch({
    url: `/api/admin/users/${id}`,
    method: "DELETE",
  });
};

StaffService.resendActivationEmail = function fn(id) {
  return fetch({
    url: "/api/admin/users/resendActivationEmail",
    method: "POST",
    data: { id },
  });
};

export default StaffService;
