import { SET_SCHOOLS, SET_SIGNUP_SUCCESS } from "redux/constants";

const initState = {
  schools: [],
  signupSuccess: {
    attemptedFormSubmit: false,
    isSuccessful: false,
    message: "",
  },
};

const signup = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SCHOOLS: {
      return {
        ...state,
        schools: payload.schools,
      };
    }
    case SET_SIGNUP_SUCCESS: {
      return {
        ...state,
        signupSuccess: payload,
      };
    }
    default:
      return state;
  }
};

export default signup;
