import {
  ADD_STAFF,
  DELETE_STAFF,
  EDIT_STAFF,
  GET_STAFF_DATA,
  SET_STAFF_DATA,
  SET_CURRENT_STAFF,
  STOP_LOADING_STAFF,
  RESEND_ACTIVATION_EMAIL,
} from "redux/constants";

export const stopLoadingStaff = () => ({
  type: STOP_LOADING_STAFF,
});

export const getStaffData = (payload) => ({
  type: GET_STAFF_DATA,
  payload,
});

export const setStaffData = (payload) => ({
  type: SET_STAFF_DATA,
  payload,
});

export const addStaff = (payload) => ({
  type: ADD_STAFF,
  payload,
});

export const setCurrentStaff = (payload) => ({
  type: SET_CURRENT_STAFF,
  payload,
});

export const editStaff = (payload) => ({
  type: EDIT_STAFF,
  payload,
});

export const deleteStaff = (payload) => ({
  type: DELETE_STAFF,
  payload,
});

export const resendActivationEmail = (payload) => ({
  type: RESEND_ACTIVATION_EMAIL,
  payload,
});
