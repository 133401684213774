import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { NAV_TYPE_TOP } from "constants/ThemeConstant";
import { Button, Radio, Dropdown, Menu, Space, Tooltip, Modal } from "antd";
import {
  AppstoreOutlined,
  PlusOutlined,
  UnorderedListOutlined,
  DownOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { VIEW_GRID, VIEW_LIST } from "constants/AppConstant";
import { useLocation } from "react-router-dom";
import {
  getAttendanceCSV,
  triggerArchiveStudents,
  triggerExportParents,
  triggerDeleteParents,
  triggerExportStudentData,
} from "redux/actions";
import moment from "moment";
import SuccessMessage from "components/custom-components/SuccessMessage";
import useBoolean from "hooks/useBoolean";
import AppBreadcrumb from "./AppBreadcrumb";

const STUDENT_MANAGEMENT = "student-management";
const ATTENDANCE_MANAGEMENT = "attendance-management";

export const PageHeader = (props) => {
  const {
    title,
    display,
    children,
    background,
    className,
    overlap,
    navType,
    label,
    onAdd,
    viewSwitch,
    onChangeView,
    getAttendanceCSV,
    studentsArchived,
    parentsExported,
    parentsDeleted,
    triggerArchiveStudents,
    triggerExportParents,
    triggerDeleteParents,
    triggerExportStudentData,
    attendanceFilters,
  } = props;

  const [widthOffset, setWidthOffset] = useState(0);
  const [showExport, setShowExport] = useBoolean(false);

  const [showStudentHeaders, setShowStudentHeaders] = useBoolean(false);

  const [showEndTerm, setShowEndTerm] = useBoolean(false);

  const [disableEndTerm, setDisableEndTerm] = useBoolean(false);

  const [archiveStudentModal, setArchiveStudentModal] = useBoolean(false);
  const [exportParentsModal, setExportParentsModal] = useBoolean(false);
  const [deleteParentsModal, setDeleteParentsModal] = useBoolean(false);

  const location = useLocation();
  const ref = useRef(null);

  useEffect(() => {
    const currentDate = moment();
    const currentMonth = currentDate.month();

    // End of term functionality can only be performed in June, July and August
    // Note: The conditions were originally meant to be June - August (5 - 7)
    // but we expanded the conditions to include September due to longer
    // dev time. This can be changed back to the original conditions at any time.
    if (!(currentMonth >= 5 && currentMonth <= 8)) {
      setDisableEndTerm.on();
    }
  }, []);

  useEffect(() => {
    if (location?.pathname) {
      setShowExport.set(location.pathname.includes(ATTENDANCE_MANAGEMENT));
      setShowEndTerm.set(location.pathname.includes(STUDENT_MANAGEMENT));
      setShowStudentHeaders.set(location.pathname.includes(STUDENT_MANAGEMENT));
    }
  }, [location]);

  useEffect(() => {
    if (navType === NAV_TYPE_TOP) {
      const windowSize = window.innerWidth;
      const pageHeaderSize = ref.current.offsetWidth;
      setWidthOffset((windowSize - pageHeaderSize) / 2);
    }
  }, [navType]);

  const handleArchiveStudents = () => {
    triggerArchiveStudents();
  };

  const handleExportParents = () => {
    triggerExportParents();
  };

  const handleDeleteParents = () => {
    triggerDeleteParents();
  };

  const handleExportCsv = () => {
    getAttendanceCSV(attendanceFilters);
  };

  const handleExportStudentData = () => {
    triggerExportStudentData();
  };

  const getStyle = () => {
    const style = {
      backgroundImage: background ? `url(${background})` : "none",
    };
    if (navType === NAV_TYPE_TOP) {
      style.marginRight = -widthOffset;
      style.marginLeft = -widthOffset;
      style.paddingLeft = 0;
      style.paddingRight = 0;
    }
    return style;
  };

  const viewOptions = [
    {
      key: VIEW_GRID,
      value: VIEW_GRID,
      icon: <AppstoreOutlined />,
    },
    {
      key: VIEW_LIST,
      value: VIEW_LIST,
      icon: <UnorderedListOutlined />,
    },
  ];

  const handleChangeView = (e) => onChangeView(e.target.value);

  const getHeader = () => {
    if (display) {
      return (
        <div className="app-page-header-content">
          <div>
            <AppBreadcrumb />
            <h3 className="mt-3 mb-0 font-weight-semibold">{title ?? null}</h3>
          </div>
          {viewSwitch ? (
            <div>
              <Radio.Group defaultValue={VIEW_GRID} onChange={handleChangeView}>
                {viewOptions.map((option) => (
                  <Radio.Button key={option.key} value={option.value}>
                    {option.icon}
                  </Radio.Button>
                ))}
              </Radio.Group>
              {label && onAdd ? (
                <Button type="primary" className="ml-2" onClick={onAdd}>
                  <PlusOutlined />
                  <span>{`Add ${label}`}</span>
                </Button>
              ) : null}
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  };

  const menu = (
    <Menu
      items={[
        {
          label: (
            <div className="end-of-term-button-container d-flex justify-content-between align-items-center">
              Archive Students
              <Button
                type="link"
                onClick={setArchiveStudentModal.on}
                disabled={studentsArchived}
                size="small"
              >
                Run
              </Button>
            </div>
          ),
          key: "0",
        },
        {
          label: (
            <div className="end-of-term-button-container d-flex justify-content-between align-items-center">
              Export Parents
              <Button
                type="link"
                onClick={setExportParentsModal.on}
                size="small"
                disabled={parentsExported}
              >
                Run
              </Button>
            </div>
          ),
          key: "1",
        },
        {
          label: (
            <div className="end-of-term-button-container d-flex justify-content-between align-items-center">
              Delete Parents
              <Button
                type="link"
                onClick={setDeleteParentsModal.on}
                size="small"
                disabled={parentsDeleted}
              >
                Run
              </Button>
            </div>
          ),
          key: "2",
        },
      ]}
    />
  );

  const archiveStudentsModalFooter = (
    <div>
      {studentsArchived ? (
        <Button type="primary" onClick={setArchiveStudentModal.off}>
          Close
        </Button>
      ) : (
        <>
          <Button onClick={setArchiveStudentModal.off}>Cancel</Button>
          <Button type="primary" danger onClick={handleArchiveStudents}>
            Archive Students
          </Button>
        </>
      )}
    </div>
  );

  const exportParentsModalFooter = (
    <div>
      {parentsExported ? (
        <Button type="primary" onClick={setExportParentsModal.off}>
          Close
        </Button>
      ) : (
        <>
          <Button onClick={setExportParentsModal.off}>Cancel</Button>
          <Button type="primary" danger onClick={handleExportParents}>
            Export Parents
          </Button>
        </>
      )}
    </div>
  );

  const deleteParentsModalFooter = (
    <div>
      {parentsDeleted ? (
        <Button type="primary" onClick={setDeleteParentsModal.off}>
          Close
        </Button>
      ) : (
        <>
          <Button onClick={setDeleteParentsModal.off}>Cancel</Button>
          <Button type="primary" danger onClick={handleDeleteParents}>
            Delete Parents
          </Button>
        </>
      )}
    </div>
  );

  return (
    <div
      ref={ref}
      className={`page-header-alt ${className ?? ""} ${
        overlap ? "overlap" : null
      } 
      ${
        showExport || showEndTerm
          ? `d-flex justify-content-between align-items-center`
          : ``
      }`}
      style={getStyle()}
    >
      {getHeader()}
      {navType === NAV_TYPE_TOP ? (
        <div className="container">{children}</div>
      ) : (
        <>{children}</>
      )}

      {showExport && (
        <Button type="primary" onClick={handleExportCsv}>
          Export Attendance
        </Button>
      )}

      {showStudentHeaders && (
        <div className="student-header-buttons">
          <Button onClick={handleExportStudentData} icon={<ExportOutlined />}>
            Export Students
          </Button>

          <Dropdown
            overlay={menu}
            trigger={["click"]}
            disabled={disableEndTerm}
            overlayClassName="end-term-dropdown"
          >
            <Tooltip
              placement="left"
              title={
                disableEndTerm
                  ? "This button will be available from June to August"
                  : "Complete all the steps to end term"
              }
            >
              <Button disabled={disableEndTerm}>
                <Space>
                  <DownOutlined />
                  End Term
                </Space>
              </Button>
            </Tooltip>
          </Dropdown>
        </div>
      )}

      <Modal
        visible={archiveStudentModal}
        title="Archive Students"
        footer={archiveStudentsModalFooter}
        closable={false}
        wrapClassName="end-of-term-modal"
      >
        {studentsArchived ? (
          <SuccessMessage message="Students have been archived" />
        ) : (
          "Are you ready to archive students? This action can not be undone."
        )}
      </Modal>

      <Modal
        visible={exportParentsModal}
        title="Export Parents"
        footer={exportParentsModalFooter}
        closable={false}
        wrapClassName="end-of-term-modal"
      >
        {parentsExported ? (
          <SuccessMessage message="Parents have been exported." />
        ) : (
          "Create a backup of the parent database by exporting a CSV file."
        )}
      </Modal>

      <Modal
        visible={deleteParentsModal}
        title="Delete Parents"
        footer={deleteParentsModalFooter}
        closable={false}
        wrapClassName="end-of-term-modal"
      >
        {parentsDeleted ? (
          <SuccessMessage message="Parents have been deleted." />
        ) : (
          <div>
            <p>
              Are you ready to delete the parent database? This action cannot be
              undone.
            </p>

            <p className="text-danger font-weight-bold">
              IMPORTANT: Make sure you have the parents exported as a backup
              file before proceeding.
            </p>
          </div>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ theme, student, parent }) => {
  const { navType } = theme;
  const {
    studentsArchived,
    parentsExported,
    parentsDeleted,
    attendanceFilters,
  } = student;
  const { parentList } = parent;

  return {
    navType,
    studentsArchived,
    parentsExported,
    parentsDeleted,
    parentList,
    attendanceFilters,
  };
};

const mapDispatchToProps = {
  getAttendanceCSV,
  triggerArchiveStudents,
  triggerExportParents,
  triggerDeleteParents,
  triggerExportStudentData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
