import { useMemo, useState } from "react";

const useBoolean = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const updateValue = useMemo(
    () => ({
      set: (newValue) => setValue(newValue),
      toggle: () => setValue((oldValue) => !oldValue),
      on: () => setValue(true),
      off: () => setValue(false),
    }),
    []
  );

  return [value, updateValue];
};
export default useBoolean;
