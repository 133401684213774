import { all, takeEvery, fork, call, put, select } from "redux-saga/effects";
import {
  ADD_DATES,
  DELETE_DATE,
  GET_CALENDAR_BY_DATE,
  GET_CALENDAR_BY_REGION,
  GET_DATEPICKER_BLACKOUT_DATES,
  GET_PAST_DATES,
  GET_UPCOMING_DATES,
  UPDATE_DATE,
} from "redux/constants";
import {
  getCalendarByRegion,
  setCalendarByDate,
  setCalendarByRegion,
  setDatepickerBlackoutDates,
  setPastDates,
  setRemovingDate,
  setSavingDate,
  setUpcomingDates,
  showNotification,
  stopLoadingCalendar,
} from "redux/actions";
import CalendarService from "services/CalendarService";
import { GENERIC_ERROR } from "constants/MessageConstant";
import Utils from "utils";

export function* fetchCalendarByRegion({ payload }) {
  try {
    const data = yield call(CalendarService.getCalendarData, payload);
    if (data) {
      yield put(setCalendarByRegion(data));
    }
  } catch (err) {
    yield put(stopLoadingCalendar());
  }
}

export function* fetchCalendarByDate({ payload }) {
  try {
    const data = yield call(CalendarService.getCalendarData, payload);
    if (data) {
      yield put(setCalendarByDate(data));
    }
  } catch (err) {
    yield put(stopLoadingCalendar());
  }
}

export function* fetchUpcomingDates({ payload }) {
  try {
    const data = yield call(CalendarService.getCalendarData, payload);
    if (data) {
      const { upcomingListView } = data;
      yield put(setUpcomingDates(upcomingListView));
    }
  } catch (err) {
    yield put(stopLoadingCalendar());
  }
}

export function* fetchPastDates({ payload }) {
  try {
    const data = yield call(CalendarService.getCalendarData, payload);
    if (data) {
      const { pastListview } = data;
      yield put(setPastDates(pastListview));
    }
  } catch (err) {
    yield put(stopLoadingCalendar());
  }
}

export function* addDates({ payload }) {
  yield put(setSavingDate(true));
  try {
    const data = yield call(CalendarService.addDates, payload);
    if (data) {
      const datesCreated = data.length > 1 ? "Dates" : "Date";
      const { selectedMonthYear, selectedRegion } = yield select(
        (state) => state.calendar
      );
      const params = Utils.getCalendarBaseParams(
        selectedMonthYear,
        selectedRegion
      );
      yield put(getCalendarByRegion(params));
      yield put(
        showNotification({
          message: `Blackout ${datesCreated} Added!`,
          type: "success",
        })
      );
    }
    yield put(setSavingDate(false));
  } catch (err) {
    yield put(setSavingDate(false));
    yield put(
      showNotification({
        message: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* deleteDate({ payload }) {
  yield put(setRemovingDate(true));
  try {
    const data = yield call(CalendarService.deleteDate, payload);
    if (data) {
      const datesDeleted = data.numDatesDeleted > 1 ? "Dates" : "Date";
      const { selectedMonthYear, selectedRegion } = yield select(
        (state) => state.calendar
      );
      const params = Utils.getCalendarBaseParams(
        selectedMonthYear,
        selectedRegion
      );
      yield put(getCalendarByRegion(params));
      yield put(
        showNotification({
          message: `Blackout ${datesDeleted} Removed!`,
          type: "success",
        })
      );
      yield put(setRemovingDate(false));
    }
  } catch (err) {
    yield put(
      showNotification({
        message: GENERIC_ERROR,
        type: "error",
      })
    );
    yield put(setRemovingDate(false));
  }
}

export function* updateDate({ payload }) {
  yield put(setSavingDate(true));
  try {
    const data = yield call(CalendarService.updateDate, payload);
    if (data) {
      const datesUpdated = data.numDatesUpdated > 1 ? "Dates" : "Date";
      const { selectedMonthYear, selectedRegion } = yield select(
        (state) => state.calendar
      );
      const params = Utils.getCalendarBaseParams(
        selectedMonthYear,
        selectedRegion
      );
      yield put(getCalendarByRegion(params));
      yield put(
        showNotification({
          message: `Blackout ${datesUpdated} Updated!`,
          type: "success",
        })
      );
      yield put(setSavingDate(false));
    }
  } catch (err) {
    yield put(
      showNotification({
        message: GENERIC_ERROR,
        type: "error",
      })
    );
    yield put(setSavingDate(false));
  }
}

export function* fetchDatepickerBlackoutDates({ payload }) {
  try {
    const { date, request } = payload;
    const data = yield call(CalendarService.getCalendarData, request);
    if (data) {
      yield put(setDatepickerBlackoutDates({ data, date }));
    }
  } catch (err) {
    yield put(stopLoadingCalendar());
  }
}

export function* watchFetchCalendarByRegion() {
  yield takeEvery(GET_CALENDAR_BY_REGION, fetchCalendarByRegion);
}

export function* watchFetchCalendarByDate() {
  yield takeEvery(GET_CALENDAR_BY_DATE, fetchCalendarByDate);
}

export function* watchFetchUpcomingDates() {
  yield takeEvery(GET_UPCOMING_DATES, fetchUpcomingDates);
}

export function* watchFetchPastDates() {
  yield takeEvery(GET_PAST_DATES, fetchPastDates);
}

export function* watchAddDates() {
  yield takeEvery(ADD_DATES, addDates);
}

export function* watchDeleteDate() {
  yield takeEvery(DELETE_DATE, deleteDate);
}

export function* watchUpdateDate() {
  yield takeEvery(UPDATE_DATE, updateDate);
}

export function* watchFetchDatepickerBlackoutDates() {
  yield takeEvery(GET_DATEPICKER_BLACKOUT_DATES, fetchDatepickerBlackoutDates);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchCalendarByRegion),
    fork(watchFetchCalendarByDate),
    fork(watchFetchUpcomingDates),
    fork(watchFetchPastDates),
    fork(watchAddDates),
    fork(watchDeleteDate),
    fork(watchUpdateDate),
    fork(watchFetchDatepickerBlackoutDates),
  ]);
}
