import {
  ADD_STUDENT,
  DELETE_STUDENT,
  EDIT_STUDENT,
  GET_PASSPHRASE,
  GET_STUDENT_DATA,
  SET_PASSPHRASE,
  SET_STUDENT_DATA,
  STOP_LOADING_STUDENT,
  GET_STUDENT_ATTENDANCE,
  SET_STUDENT_ATTENDANCE,
  GET_ATTENDANCE_PROGRAM_FILTER,
  SET_ATTENDANCE_PROGRAM_FILTER,
  GET_ATTENDANCE_STUDENT_FILTER,
  SET_ATTENDANCE_STUDENT_FILTER,
  GET_ATTENDANCE_POD_FILTER,
  SET_ATTENDANCE_POD_FILTER,
  GET_ATTENDANCE_CSV,
  ARCHIVE_STUDENTS,
  SET_ARCHIVE_STUDENTS,
  EXPORT_PARENTS,
  SET_EXPORT_PARENTS,
  DELETE_PARENTS,
  SET_DELETE_PARENTS,
  SET_ATTENDANCE_FILTERS,
  EXPORT_STUDENT_DATA,
} from "redux/constants";

export const stopLoadingStudent = () => ({
  type: STOP_LOADING_STUDENT,
});

export const getStudentData = (payload) => ({
  type: GET_STUDENT_DATA,
  payload,
});

export const setStudentData = (payload) => ({
  type: SET_STUDENT_DATA,
  payload,
});

export const addStudent = (payload) => ({
  type: ADD_STUDENT,
  payload,
});

export const deleteStudent = (payload) => ({
  type: DELETE_STUDENT,
  payload,
});

export const editStudent = (payload) => ({
  type: EDIT_STUDENT,
  payload,
});

export const getPassphrase = (payload) => ({
  type: GET_PASSPHRASE,
  payload,
});

export const setPassphrase = (payload) => ({
  type: SET_PASSPHRASE,
  payload,
});

export const getStudentAttendance = (payload) => ({
  type: GET_STUDENT_ATTENDANCE,
  payload,
});

export const setStudentAttendance = (payload) => ({
  type: SET_STUDENT_ATTENDANCE,
  payload,
});

export const getAttendanceProgramFilter = (payload) => ({
  type: GET_ATTENDANCE_PROGRAM_FILTER,
  payload,
});

export const setAttendanceProgramFilter = (payload) => ({
  type: SET_ATTENDANCE_PROGRAM_FILTER,
  payload,
});

export const getAttendanceStudentFilter = (payload) => ({
  type: GET_ATTENDANCE_STUDENT_FILTER,
  payload,
});

export const setAttendanceStudentFilter = (payload) => ({
  type: SET_ATTENDANCE_STUDENT_FILTER,
  payload,
});

export const getAttendancePodFilter = (payload) => ({
  type: GET_ATTENDANCE_POD_FILTER,
  payload,
});

export const setAttendancePodFilter = (payload) => ({
  type: SET_ATTENDANCE_POD_FILTER,
  payload,
});

export const getAttendanceCSV = (payload) => ({
  type: GET_ATTENDANCE_CSV,
  payload,
});

export const triggerArchiveStudents = () => ({
  type: ARCHIVE_STUDENTS,
});

export const setArchiveStudents = (payload) => ({
  type: SET_ARCHIVE_STUDENTS,
  payload,
});

export const triggerExportParents = () => ({
  type: EXPORT_PARENTS,
});

export const setExportParents = (payload) => ({
  type: SET_EXPORT_PARENTS,
  payload,
});

export const triggerDeleteParents = () => ({
  type: DELETE_PARENTS,
});

export const setDeleteParents = (payload) => ({
  type: SET_DELETE_PARENTS,
  payload,
});

export const setAttendanceFilters = (payload) => ({
  type: SET_ATTENDANCE_FILTERS,
  payload,
});

export const triggerExportStudentData = () => ({
  type: EXPORT_STUDENT_DATA,
});
