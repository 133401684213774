import moment from "moment";
import { isArray } from "lodash";
import {
  ALL_REGIONS_TEXT,
  DAYS_OF_WEEK,
  STUDENT_WORKSHEET_TYPE,
} from "constants/AppConstant";

class Utils {
  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name) {
    let initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(navTree, path, i = 0) {
    if (navTree.path === path) {
      return navTree;
    }
    let route;
    for (let p in navTree) {
      if (navTree.hasOwnProperty(p) && typeof navTree[p] === "object") {
        route = this.getRouteInfo(navTree[p], path, i++);
        if (route) {
          return route;
        }
      }
    }
    return route;
  }

  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getColorContrast(hex) {
    if (!hex) {
      return "dark";
    }
    const threshold = 130;
    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);
    function hexToR(h) {
      return parseInt(cutHex(h).substring(0, 2), 16);
    }
    function hexToG(h) {
      return parseInt(cutHex(h).substring(2, 4), 16);
    }
    function hexToB(h) {
      return parseInt(cutHex(h).substring(4, 6), 16);
    }
    function cutHex(h) {
      return h.charAt(0) === "#" ? h.substring(1, 7) : h;
    }
    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
    if (cBrightness > threshold) {
      return "dark";
    } else {
      return "light";
    }
  }

  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    const RR =
      R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG =
      G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB =
      B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);
    return `#${RR}${GG}${BB}`;
  }

  /**
   * Convert RGBA to HEX
   * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
   * @return {String} HEX color
   */
  static rgbaToHex(rgba) {
    const trim = (str) => str.replace(/^\s+|\s+$/gm, "");
    const inParts = rgba.substring(rgba.indexOf("(")).split(","),
      r = parseInt(trim(inParts[0].substring(1)), 10),
      g = parseInt(trim(inParts[1]), 10),
      b = parseInt(trim(inParts[2]), 10),
      a = parseFloat(
        trim(inParts[3].substring(0, inParts[3].length - 1))
      ).toFixed(2);
    const outParts = [
      r.toString(16),
      g.toString(16),
      b.toString(16),
      Math.round(a * 255)
        .toString(16)
        .substring(0, 2),
    ];

    outParts.forEach(function (part, i) {
      if (part.length === 1) {
        outParts[i] = "0" + part;
      }
    });
    return `#${outParts.join("")}`;
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number, positive, negative) {
    if (number > 0) {
      return positive;
    }
    if (number < 0) {
      return negative;
    }
    return null;
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === "number" && typeof b[key] === "number") {
      return a[key] - b[key];
    }

    if (typeof a[key] === "string" && typeof b[key] === "string") {
      a = a[key].toLowerCase();
      b = b[key].toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
    return;
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] === value);
    }
    return data;
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] !== value);
    }
    return data;
  }

  /**
   * Wild card search on all property of the object
   * @param {Number | String} input - any value to search
   * @param {Array} list - array for search
   * @return {Array} array of object contained keyword
   */
  static wildCardSearch(list, input) {
    const searchText = (item) => {
      for (let key in item) {
        if (item[key] == null) {
          continue;
        }
        if (
          item[key]
            .toString()
            .toUpperCase()
            .indexOf(input.toString().toUpperCase()) !== -1
        ) {
          return true;
        }
      }
    };
    list = list.filter((value) => searchText(value));
    return list;
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    let breakpoints = [];
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key];
        if (element) {
          breakpoints.push(key);
        }
      }
    }
    return breakpoints;
  }

  /**
   * Modify filter params
   * @param {Object} params - filter query params
   * @return {Object} object that contains comma-separated values for each query param
   */
  static modifyFilterParams(params) {
    let modifiedParams = {};
    for (const key in params) {
      if (params[key]) {
        modifiedParams[key] = params[key].join();
      }
    }
    return modifiedParams;
  }

  /**
   * Modify sorter params
   * @param {Object} params - sorter query params
   * @return {Object} object that contains values with query param and sort props, support for multi-sort
   */
  static modifySorterParams(params) {
    let modifiedParams = {};
    if (params) {
      const sorters = isArray(params) ? params : [params];
      modifiedParams = sorters.reduce(
        (prev, curr) => ({
          sortBy: [
            ...(prev.sortBy || []),
            curr.order
              ? `${curr.field},${curr.order === "ascend" ? "ASC" : "DESC"}`
              : "",
          ],
        }),
        {}
      );
    }
    return modifiedParams;
  }

  /**
   * Calculate session date from session's start date, week number, and session number
   * @param {String} startDate - date string
   * @param {Array} days - days that session runs during the week
   * @param {Number} week - session week
   * @param {Number} sessionNumber - session number
   * @return {Object} Moment Date object
   */
  static calculateSessionDate(startDate, days, week, sessionNumber) {
    const adjuster = days.length === 1 ? 0 : sessionNumber - 1;
    return moment(startDate)
      .add(week - 1, "week")
      .day(DAYS_OF_WEEK.filter((day) => day.key === days[adjuster])[0].fullText)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  }

  /**
   * Generate filter options for session materials
   * @param {Array} materials - list of session material objects
   * @return {Array} list of objects that can be used in a dropdown
   */
  static generateFilterOptions({ materials, key }) {
    const uniqueMaterialKeys = [
      ...new Set(materials.map((material) => material[key])),
    ].filter((key) => !!key);

    const getDisplayText = (value) => {
      switch (value) {
        case "link":
        case "student": {
          return value.charAt(0).toUpperCase() + value.slice(1);
        }
        case "coach": {
          return "Preparation";
        }
        default: {
          return value.toUpperCase();
        }
      }
    };

    const materialFilters = uniqueMaterialKeys.map((_key) => ({
      key: _key,
      value: _key,
      text: getDisplayText(_key),
    }));
    return materialFilters;
  }

  /**
   * Check if file meets upload requirements
   * @param {Object} file - file object from Uploader component
   * @param {Number} maxSize - maximum file size allowed
   * @param {Boolean} mustBeImage - image type required
   * @param {Boolean} allowMultiple - multiple files permitted
   * @param {Array} fileList - list of file objects from Uploader component
   * @return {Object} result object with isValid and message properties
   */
  static checkUploadRequirements(
    file,
    maxSize,
    mustBeImage = false,
    allowMultiple = true,
    fileList
  ) {
    const BYTE_MULTIPLIER = 1024;

    let isValid = true;
    let description = "";

    const meetsSizeReqs =
      file.size / BYTE_MULTIPLIER / BYTE_MULTIPLIER < maxSize;
    if (!meetsSizeReqs) {
      isValid = false;
      description = `File is too large. Please upload a file less than ${maxSize}MB in size.`;
    }

    if (mustBeImage) {
      const isImageFileType =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isImageFileType) {
        isValid = false;
        description = "File format is invalid. Please upload a JPG/PNG file.";
      }
    }

    if (!allowMultiple && fileList.length > 1) {
      const index = fileList.findIndex((_file) => _file.uid === file.uid);
      if (index === 0) {
        description = "Too many files. Please upload only 1 file.";
      } else {
        description = "";
      }
      isValid = false;
    }

    return { isValid, description };
  }

  /**
   * Check if link string starts with http protocol
   * @param {String} link - link string
   * @return {Boolean} link protocol exists
   */
  static checkForProtocol(string) {
    return string.startsWith("http://") || string.startsWith("https://");
  }

  /**
   * Create download link with formatted datestring
   * @param {String} link - link string
   * @return {Boolean} link protocol exists
   */
  static createDownloadLink(data, type, title) {
    const blob = new Blob([data], { type });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${title}-${moment().format("YYYY-MM-DD")}.csv`;
    link.style.visibility = "hidden";
    return link;
  }

  /**
   * Get range of dates between and including two dates
   * @param {date|moment} start - start date
   * @param {date|moment} end - end date
   * @param {string} type - range type eg. 'days', 'hours', etc.
   * @return {Array} array of dates within range
   */
  static getDateRange(startDate, endDate, type) {
    let fromDate = moment(startDate);
    let toDate = moment(endDate);
    let diff = toDate.diff(fromDate, type);
    let range = [];
    for (let i = 0; i <= diff; i++) {
      range.push(moment(startDate).add(i, type));
    }
    return range;
  }

  /**
   * Get base params for blackout calendar
   * @param {Object} date - selected month and year { calendarMonth: Integer, calendarYear: Integer }
   * @param {string} region - selected region
   * @return {string} type - range type eg. 'days', 'hours', etc.
   */
  static getCalendarBaseParams(date, region) {
    let params = {};
    if (date.calendarMonth && date.calendarYear) {
      params = { ...date };
    }
    if (region && region !== ALL_REGIONS_TEXT) {
      params.region = region;
    }
    return params;
  }

  static formatNotificationDate(date) {
    const notificationDate = moment(date);
    const currentDate = moment();

    const difference = currentDate.diff(notificationDate, "days");

    if (difference === 7) {
      return "Last week";
    }

    if (difference > 7) {
      return notificationDate.format("MMMM Do, YYYY");
    }
    return notificationDate.from(currentDate);
  }

  static sessionHasStudentWorksheet(attachments) {
    return (
      attachments.filter(
        (attachment) => attachment.type === STUDENT_WORKSHEET_TYPE.value
      ).length > 0
    );
  }
}

export default Utils;
