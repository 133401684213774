import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import Common from "./Common";
import Staff from "./Staff";
import Session from "./Session";
import Program from "./Program";
import Student from "./Student";
import School from "./School";
import Parent from "./Parent";
import Pod from "./Pod";
import Profile from "./Profile";
import Signup from "./Signup";
import StudentPortal from "./StudentPortal";
import StaffPortal from "./StaffPortal";
import OrganizationConstants from "./OrganizationConstants";
import Calendar from "./Calendar";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  common: Common,
  staff: Staff,
  session: Session,
  program: Program,
  student: Student,
  school: School,
  parent: Parent,
  pod: Pod,
  profile: Profile,
  signup: Signup,
  studentPortal: StudentPortal,
  staffPortal: StaffPortal,
  organizationConstants: OrganizationConstants,
  calendar: Calendar,
});

export default reducers;
