const { useEffect } = require("react");

const useClickOutsideElement = (ref, callback) => {
  const handleOutsideClick = (e) => {
    if (ref?.current && !ref?.current?.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);
};

export default useClickOutsideElement;
