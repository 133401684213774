export const STOP_LOADING_POD = "STOP_LOADING_POD";
export const GET_POD_DATA = "GET_POD_DATA";
export const SET_POD_DATA = "SET_POD_DATA";
export const CREATE_POD = "CREATE_POD";
export const GET_POD_BY_ID = "GET_POD_BY_ID";
export const SET_CURRENT_POD = "SET_CURRENT_POD";
export const EDIT_POD = "EDIT_POD";
export const ARCHIVE_POD = "ARCHIVE_POD";
export const ADD_STUDENT_TO_POD = "ADD_STUDENT_TO_POD";
export const ADD_COACH_TOP_POD = "ADD_COACH_TOP_POD";
export const REMOVE_STUDENT_FROM_POD = "REMOVE_STUDENT_FROM_POD";
export const REMOVE_COACH_FROM_POD = "REMOVE_COACH_FROM_POD";
export const GET_POD_MESSAGE_BOARD = "GET_POD_MESSAGE_BOARD";
export const SET_POD_MESSAGE_BOARD = "SET_POD_MESSAGE_BOARD";
export const ADD_POST_TO_MESSAGE_BOARD = "ADD_POST_TO_MESSAGE_BOARD";
export const DELETE_POST_FROM_MESSAGE_BOARD = "DELETE_POST_FROM_MESSAGE_BOARD";
export const CHANGE_MESSAGEBOARD_PAGE = "CHANGE_MESSAGEBOARD_PAGE";
export const GET_NOTIFICATION_THREAD = "GET_NOTIFICATION_THREAD";
export const SET_NOTIFICATION_THREAD = "SET_NOTIFICATION_THREAD";
export const SET_NOTIFICATION_THREAD_LOADING =
  "SET_NOTIFICATION_THREAD_LOADING";
