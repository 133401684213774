import fetch from "auth/FetchInterceptor";
import { STUDENT, ADMIN, STAFF } from "constants/ThemeConstant";

const ProfileService = {};

ProfileService.getProfile = function fn(userRole) {
  return fetch({
    url: `/api/${userRole === STUDENT ? `${userRole}s` : userRole}/profile`,
    method: "GET",
  });
};

ProfileService.uploadPhoto = function fn({ formData, userRole }) {
  return fetch({
    url: `/api/${
      userRole === STUDENT
        ? `${userRole}s`
        : `${userRole}${userRole === ADMIN ? "/profile" : ""}`
    }/profilePhoto`,
    method: "POST",
    data: formData,
  });
};

ProfileService.removePhoto = function fn(userRole) {
  return fetch({
    url: `/api/${
      userRole === STUDENT
        ? `${userRole}s`
        : `${userRole}${userRole === ADMIN ? "/profile" : ""}`
    }/profilePhoto`,
    method: "DELETE",
  });
};

ProfileService.editProfile = function fn({ values, userRole }) {
  return fetch({
    url: `/api/${userRole}/profile`,
    method: "PUT",
    data: values,
  });
};

ProfileService.changePassword = function fn({ values, userRole }) {
  return fetch({
    url: `/api/${userRole}${
      userRole === ADMIN ? "/profile" : ""
    }/changePassword`,
    method: "POST",
    data: values,
  });
};

ProfileService.getAvatars = async function fn(userRole, callback) {
  const avatars = await fetch({
    url: `/api/${`${userRole}s/profile/avatars`}`,
    method: "GET",
  });

  callback(avatars);

  return avatars;
};

ProfileService.saveAvatar = async function fn({ category, name }) {
  await fetch({
    url: `/api/students/profile/saveAvatar?category=${category}&fileName=${name}`,
    method: "POST",
  });
};

ProfileService.getNotifications = function fn(payload) {
  const user = payload === STAFF ? "staff" : "students";
  return fetch({
    url: `/api/${user}/notifications`,
    method: "GET",
  });
};

ProfileService.markNotificationsRead = function fn({
  notificationIds,
  updateAll,
  userRole,
}) {
  const user = userRole === STUDENT ? "students" : "staff";

  return fetch({
    url: `/api/${user}/notifications`,
    method: "PUT",
    data: {
      notificationIds,
      updateAll,
    },
  });
};

ProfileService.deleteNotification = function fn(data) {
  const user = data.userRole === STUDENT ? "students" : "staff";

  return fetch({
    url: `/api/${user}/notifications`,
    method: "DELETE",
    data,
  });
};

export default ProfileService;
