import {
  GET_SCHOOLS,
  SUBMIT_SIGNUP,
  SET_SCHOOLS,
  SET_SIGNUP_SUCCESS,
} from "redux/constants/";

export const getSchools = () => ({
  type: GET_SCHOOLS,
});

export const setSchools = (payload) => ({
  type: SET_SCHOOLS,
  payload,
});

export const submitSignupForm = (payload) => ({
  type: SUBMIT_SIGNUP,
  payload,
});

export const setSignupSuccess = (payload) => ({
  type: SET_SIGNUP_SUCCESS,
  payload,
});
