export const STOP_LOADING_SESSION = "STOP_LOADING_SESSION";
export const GET_SESSIONS = "GET_SESSIONS";
export const SET_SESSIONS = "SET_SESSIONS";
export const GET_SESSION_BY_ID = "GET_SESSION_BY_ID";
export const SET_CURRENT_SESSION = "SET_CURRENT_SESSION";
export const SET_SESSION_MATERIALS = "SET_SESSION_MATERIALS";
export const REMOVE_SESSION_MATERIAL = "REMOVE_SESSION_MATERIAL";
export const UPLOAD_ATTACHMENT = "UPLOAD_ATTACHMENT";
export const UPLOAD_ATTACHMENT_SUCCESS = "UPLOAD_ATTACHMENT_SUCCESS";
export const REMOVE_ATTACHMENT = "REMOVE_ATTACHMENT";
export const REMOVE_ATTACHMENT_SUCCESS = "REMOVE_ATTACHMENT_SUCCESS";
export const CREATE_SESSION = "CREATE_SESSION";
export const CLEAR_ATTACHMENTS = "CLEAR_ATTACHMENTS";
export const ADD_ATTACHMENTS = "ADD_ATTACHMENTS";
export const EDIT_SESSION = "EDIT_SESSION";
export const REMOVE_LINK = "REMOVE_LINK";
export const CHANGE_SESSION_STATUS = "CHANGE_SESSION_STATUS";
export const DELETE_SESSION = "DELETE_SESSION";
export const CHANGE_STATUS_TAB = "CHANGE_STATUS_TAB";
