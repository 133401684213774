import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import ParentService from "services/ParentService";
import { EDIT_PARENT, GET_PARENT_DATA } from "redux/constants";
import {
  getStudentData,
  setCurrentParent,
  setParentData,
  showNotification,
  stopLoadingParent,
} from "redux/actions";
import { GENERIC_ERROR, SAVED_SUCCESS } from "constants/MessageConstant";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "constants/AppConstant";

export function* fetchParentData({ payload }) {
  try {
    const data = yield call(ParentService.getParentData, payload);
    if (data && data.parents) {
      const { count, totalPages, currentPage, parents } = data;
      yield put(
        setParentData({
          count,
          totalPages,
          currentPage,
          parentList: parents,
          pageSize: payload.size,
        })
      );
    }
  } catch (err) {
    yield put(stopLoadingParent());
  }
}

export function* editParent({ payload }) {
  try {
    yield call(ParentService.editParent, payload);
    yield put(setCurrentParent(payload.values));
    yield put(
      showNotification({
        message: "Parent info successfully updated.",
        description: SAVED_SUCCESS,
        type: "success",
      })
    );
    yield put(
      getStudentData({
        page: DEFAULT_PAGE,
        size: DEFAULT_PAGE_SIZE,
        sortBy: ["updatedAt,DESC"],
      })
    );
  } catch (err) {
    yield put(stopLoadingParent());
    yield put(
      showNotification({
        message: "Failed to update parent info.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* watchFetchParentData() {
  yield takeEvery(GET_PARENT_DATA, fetchParentData);
}

export function* watchEditParent() {
  yield takeEvery(EDIT_PARENT, editParent);
}

export default function* rootSaga() {
  yield all([fork(watchFetchParentData), fork(watchEditParent)]);
}
