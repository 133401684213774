import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import {
  END_SESSION,
  GET_STAFF_DASHBOARD_DATA,
  START_SESSION,
  SUBMIT_ATTENDANCE,
  GET_STAFF_PAST_SESSIONS,
  GET_STAFF_SESSION_BY_ID,
  UPDATE_POD_LINK,
} from "redux/constants";
import {
  endSession,
  getStaffDashboardData,
  setStaffDashboardData,
  showNotification,
  setStaffCurrentSessionPod,
  setStaffPastSessions,
  getStaffPastSessions,
  stopLoadingStaffPortal,
  setCurrentPodLink,
} from "redux/actions";
import StaffPortalService from "services/StaffPortalService";
import { MARK_SUBMISSION } from "redux/constants/StaffPortal";
import { GENERIC_ERROR } from "constants/MessageConstant";

export function* fetchDashboardData() {
  try {
    const data = yield call(StaffPortalService.getStaffDashboardData);
    if (data) {
      yield put(setStaffDashboardData(data));
    }
  } catch (err) {
    yield put(stopLoadingStaffPortal());
  }
}

export function* startSession({ payload }) {
  try {
    yield call(StaffPortalService.startSession, payload);
    yield put(getStaffDashboardData());
    yield put(
      showNotification({
        message: "Session successfully started.",
        type: "success",
      })
    );
  } catch (err) {
    yield put(stopLoadingStaffPortal());
    yield put(
      showNotification({
        message: "Failed to start session.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* finishSession({ payload }) {
  try {
    yield call(StaffPortalService.endSession, payload);
    yield put(getStaffPastSessions());
    yield put(getStaffDashboardData());
    yield put(
      showNotification({
        message: "Session successfully ended.",
        type: "success",
      })
    );
  } catch (err) {
    yield put(stopLoadingStaffPortal());
    yield put(
      showNotification({
        message: "Failed to end session.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* submitAttendance({ payload }) {
  try {
    const { podId, sessionId } = payload;
    yield call(StaffPortalService.submitAttendance, payload);
    yield put(endSession({ podId, sessionId }));
    yield put(
      showNotification({
        message: "Attendance successfully submitted.",
        type: "success",
      })
    );
  } catch (err) {
    yield put(stopLoadingStaffPortal());
    yield put(
      showNotification({
        message: "Failed to submit attendance.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* fetchPastSessions() {
  try {
    const data = yield call(StaffPortalService.getStaffPastSessions);
    if (data) {
      yield put(setStaffPastSessions(data));
    }
  } catch (err) {
    yield put(stopLoadingStaffPortal());
  }
}

export function* fetchStaffSessionById({ payload }) {
  try {
    const data = yield call(StaffPortalService.getStaffSessionById, payload);
    if (data) {
      yield put(setStaffCurrentSessionPod(data[0]));
    }
  } catch (err) {
    yield put(stopLoadingStaffPortal());
  }
}

export function* markSubmission({ payload }) {
  try {
    yield call(StaffPortalService.markSubmission, payload);
  } catch (err) {
    yield put(stopLoadingStaffPortal());
  }
}

export function* updatePodLink({ payload }) {
  try {
    const {
      values: { link },
    } = payload;
    yield call(StaffPortalService.updatePodLink, payload);
    yield put(setCurrentPodLink(link));
    yield put(
      showNotification({
        message: "Link successfully updated.",
        type: "success",
      })
    );
    yield put(getStaffDashboardData());
  } catch (err) {
    yield put(stopLoadingStaffPortal());
    yield put(
      showNotification({
        message: "Failed to update link.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* watchFetchDashboardData() {
  yield takeEvery(GET_STAFF_DASHBOARD_DATA, fetchDashboardData);
}

export function* watchStartSession() {
  yield takeEvery(START_SESSION, startSession);
}

export function* watchFinishSession() {
  yield takeEvery(END_SESSION, finishSession);
}

export function* watchSubmitAttendance() {
  yield takeEvery(SUBMIT_ATTENDANCE, submitAttendance);
}

export function* watchFetchPastSessions() {
  yield takeEvery(GET_STAFF_PAST_SESSIONS, fetchPastSessions);
}

export function* watchFetchStaffSessionById() {
  yield takeEvery(GET_STAFF_SESSION_BY_ID, fetchStaffSessionById);
}

export function* watchMarkSubmission() {
  yield takeEvery(MARK_SUBMISSION, markSubmission);
}

export function* watchUpdatePodLink() {
  yield takeEvery(UPDATE_POD_LINK, updatePodLink);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchDashboardData),
    fork(watchStartSession),
    fork(watchFinishSession),
    fork(watchSubmitAttendance),
    fork(watchFetchPastSessions),
    fork(watchFetchStaffSessionById),
    fork(watchMarkSubmission),
    fork(watchUpdatePodLink),
  ]);
}
