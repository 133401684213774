import React, { useEffect } from "react";
import MessageThread from "components/custom-components/MessageBoard/MessageThread";
import DetailsDrawer from "components/custom-components/DetailsDrawer";
import { connect } from "react-redux";
import {
  getNotificationThread,
  setNotificationThread,
  deletePostFromMessageBoard,
} from "redux/actions";
import LoadingSpinner from "components/custom-components/LoadingSpinner";
import MessageDeleted from "components/custom-components/MessageDeleted";

const MessageBoardDrawer = ({
  isVisible,
  onClose,
  messageId,
  userRole,
  pageSize,
  getNotificationThread,
  selectedNotification,
  setNotificationThread,
  getNotificationThreadLoading,
  currentPage,
  deletePostFromMessageBoard,
  ...rest
}) => {
  const getThread = () =>
    getNotificationThread({ threadId: messageId, userRole });

  useEffect(() => {
    if (messageId) {
      getThread();
      return;
    }
    setNotificationThread(null);
  }, [messageId]);

  const deleteComment = (messageId, podId) => {
    deletePostFromMessageBoard({
      messageId,
      userRole,
      podId,
      size: pageSize,
      currentPage,
    });
  };

  return (
    <DetailsDrawer
      visible={isVisible}
      label="Reply in Thread"
      handleClose={onClose}
    >
      {getNotificationThreadLoading && <LoadingSpinner />}
      {!getNotificationThreadLoading && selectedNotification && (
        <MessageThread
          selectedMessage={selectedNotification}
          parentPost={selectedNotification}
          userRole={userRole}
          deleteComment={deleteComment}
          size={pageSize}
          {...rest}
        />
      )}
      {!getNotificationThreadLoading && !selectedNotification && (
        <MessageDeleted />
      )}
    </DetailsDrawer>
  );
};

const mapStateToProps = ({ auth, pod }) => {
  const { userRole } = auth;
  const {
    messageBoard,
    currentPage,
    pageSize,
    selectedNotification,
    getNotificationThreadLoading,
  } = pod;

  return {
    userRole,
    messageBoard,
    currentPage,
    pageSize,
    selectedNotification,
    getNotificationThreadLoading,
  };
};

const mapDispatchToProps = {
  getNotificationThread,
  setNotificationThread,
  deletePostFromMessageBoard,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageBoardDrawer);
