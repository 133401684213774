import { all } from "redux-saga/effects";
import Auth from "./Auth";
import Staff from "./Staff";
import Session from "./Session";
import Program from "./Program";
import Student from "./Student";
import School from "./School";
import Parent from "./Parent";
import Pod from "./Pod";
import Profile from "./Profile";
import Signup from "./Signup";
import StudentPortal from "./StudentPortal";
import StaffPortal from "./StaffPortal";
import EmergencyContact from "./EmergencyContact";
import OrganizationConstants from "./OrganizationConstants";
import Calendar from "./Calendar";

export default function* rootSaga() {
  yield all([
    Auth(),
    Staff(),
    Session(),
    Program(),
    Student(),
    School(),
    Parent(),
    Pod(),
    Profile(),
    Signup(),
    StudentPortal(),
    StaffPortal(),
    EmergencyContact(),
    OrganizationConstants(),
    Calendar(),
  ]);
}
