import {
  GET_STUDENT_DASHBOARD_DATA,
  GET_STUDENT_PAST_SESSIONS,
  GET_STUDENT_SESSION_BY_ID,
  REMOVE_SUBMISSION_SUCCESS,
  SET_BADGES,
  SET_CURRENT_STUDENT_SESSION,
  SET_STUDENT_PAST_SESSIONS,
  SET_PAST_SUBMISSIONS,
  SET_STUDENT_DASHBOARD_DATA,
  UPLOAD_SUBMISSION_SUCCESS,
  STOP_LOADING_STUDENT_PORTAL,
  SET_POD_LEADERBOARD,
} from "redux/constants";

const initState = {
  dashboard: null,
  badges: [],
  submissions: {
    journal: null,
    challenges: null,
  },
  pastSessionsData: null,
  currentSession: null,
  loading: false,
};

const studentPortal = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STOP_LOADING_STUDENT_PORTAL: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_STUDENT_DASHBOARD_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_STUDENT_DASHBOARD_DATA: {
      return {
        ...state,
        dashboard: payload,
      };
    }
    case SET_BADGES: {
      return {
        ...state,
        badges: payload,
      };
    }
    case UPLOAD_SUBMISSION_SUCCESS: {
      const { sessionId, status: submissionType } = payload;
      return {
        ...state,
        submissions: {
          ...state.submissions,
          [submissionType]: {
            ...state.submissions[submissionType],
            [sessionId]: payload,
          },
        },
      };
    }
    case REMOVE_SUBMISSION_SUCCESS: {
      const { sessionId, type: submissionType } = payload;
      const tempSubmissions = { ...state.submissions };
      delete tempSubmissions[submissionType][sessionId];
      return {
        ...state,
        submissions: {
          ...tempSubmissions,
        },
      };
    }
    case GET_STUDENT_PAST_SESSIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_STUDENT_PAST_SESSIONS: {
      return {
        ...state,
        pastSessionsData: payload,
        loading: false,
      };
    }
    case SET_PAST_SUBMISSIONS: {
      return {
        ...state,
        submissions: payload,
      };
    }
    case GET_STUDENT_SESSION_BY_ID: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_CURRENT_STUDENT_SESSION: {
      return {
        ...state,
        loading: false,
        currentSession: payload,
      };
    }
    case SET_POD_LEADERBOARD: {
      return {
        ...state,
        loading: false,
        leaderboard: payload,
      };
    }
    default:
      return state;
  }
};

export default studentPortal;
