import fetch from "auth/FetchInterceptor";

const EmergencyContactService = {};

// Emergency contact is created automatically when creating a new student
// either by using the signup form or the admin portal.
// This function will not likely be needed unless changes to the creation
// process are made.
EmergencyContactService.createEmergencyContact = function fn(payload) {
  const { firstName, lastName, relationship, phone, phoneType } = payload;

  return fetch({
    url: "/api/admin/emergencyContacts/",
    method: "POST",
    data: {
      firstName,
      lastName,
      relationship,
      phone,
      phoneType,
    },
  });
};

EmergencyContactService.updateEmergencyContact = function fn(payload) {
  const { firstName, lastName, relationship, phone, phoneType } = payload;

  return fetch({
    url: `/api/admin/emergencyContacts/${payload.id}`,
    method: "PUT",
    data: {
      firstName,
      lastName,
      relationship,
      phone,
      phoneType,
    },
  });
};

export default EmergencyContactService;
