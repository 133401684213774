import {
  AUTHENTICATE_ADMIN,
  AUTHENTICATE_ADMIN_SUCCESS,
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  HIDE_AUTH_MESSAGE,
  LOGIN_AS_USER,
  LOGIN_AS_USER_SUCCESS,
  RESET,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  SHOW_AUTH_MESSAGE,
  SIGNOUT,
  VERIFY_ACCOUNT,
  VERIFY_ACCOUNT_SUCCESS,
} from "redux/constants";

const initState = {
  loading: false,
  messageContent: "",
  messageType: "info",
  showMessage: false,
  redirect: "/",
  token: null,
  userRole: "",
  impersonatorId: null,
};

const auth = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESET: {
      return {
        ...initState,
        userRole: state.userRole,
      };
    }
    case AUTHENTICATE_ADMIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case AUTHENTICATE_ADMIN_SUCCESS: {
      const { token, role } = payload;
      return {
        ...state,
        loading: false,
        redirect: "/admin/student-management",
        token,
        userRole: role,
      };
    }
    case AUTHENTICATE_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case AUTHENTICATE_USER_SUCCESS: {
      const { token, role } = payload;
      return {
        ...state,
        loading: false,
        redirect: `/${role}/dashboard`,
        token,
        userRole: role,
      };
    }
    case SHOW_AUTH_MESSAGE: {
      return {
        ...state,
        messageContent: payload.messageContent,
        messageType: payload.messageType || "info",
        showMessage: true,
        loading: false,
      };
    }
    case HIDE_AUTH_MESSAGE: {
      return {
        ...state,
        messageContent: "",
        messageType: "info",
        showMessage: false,
      };
    }
    case SIGNOUT: {
      return {
        ...state,
        token: null,
        userRole: "",
        redirect: "/",
        loading: false,
        impersonatorId: null,
      };
    }
    case VERIFY_ACCOUNT: {
      return {
        ...state,
        loading: true,
      };
    }
    case VERIFY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        redirect: "/verifysuccess",
        userRole: payload,
        loading: false,
      };
    }
    case FORGOT_PASSWORD: {
      return {
        ...state,
        loading: true,
      };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        redirect: "/forgotpasswordsuccess",
        loading: false,
      };
    }
    case RESET_PASSWORD: {
      return {
        ...state,
        loading: true,
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        redirect: "/resetpasswordsuccess",
        userRole: payload,
        loading: false,
      };
    }
    case LOGIN_AS_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOGIN_AS_USER_SUCCESS: {
      return {
        ...state,
        impersonatorId: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default auth;
