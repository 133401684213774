import axios from "axios";
import { notification } from "antd";

import { API_BASE_URL } from "configs/AppConfig";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { GENERIC_ERROR } from "constants/MessageConstant";
import history from "../history";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// config
const TOKEN_PAYLOAD_KEY = "Authorization";

// API request interceptor
service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${token}`;
    } else {
      delete config.headers[TOKEN_PAYLOAD_KEY];
    }
    return config;
  },
  (error) => {
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.log("ERROR:", error);

    const notificationParam = {
      message: "Uh-oh!",
      description: GENERIC_ERROR,
    };

    // remove token and redirect
    if (error.response.status === 403 || error.response.status === 401) {
      notificationParam.message = "Authentication Fail";
      notificationParam.description = "Please try again.";
      if (
        !history.location.pathname.includes("/verify") &&
        !history.location.pathname.includes("/resetpassword")
      ) {
        localStorage.clear();

        const { pathname } = history.location;
        const userRole = pathname.split("/")[1];
        history.push(`/${userRole}/login`);
        window.location.reload();
      }
    }

    if (error.response.status === 400) {
      notificationParam.description = error.response.data.message;
    }

    if (error.response.status === 404) {
      notificationParam.message = "Not Found";
    }

    if (error.response.status === 500) {
      notificationParam.message = "Internal Server Error";
    }

    if (error.response.status === 508) {
      notificationParam.message = "Time Out";
    }

    if (
      error.response.status !== 401 &&
      !history.location.pathname.includes("/login")
    ) {
      notification.error(notificationParam);
    }

    return Promise.reject(error);
  }
);

export default service;
