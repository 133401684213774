export const STOP_LOADING_STUDENT = "STOP_LOADING_STUDENT";
export const GET_STUDENT_DATA = "GET_STUDENT_DATA";
export const SET_STUDENT_DATA = "SET_STUDENT_DATA";
export const ADD_STUDENT = "ADD_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const EDIT_STUDENT = "EDIT_STUDENT";
export const GET_PASSPHRASE = "GET_PASSPHRASE";
export const SET_PASSPHRASE = "SET_PASSPHRASE";
export const GET_STUDENT_ATTENDANCE = "GET_STUDENT_ATTENDANCE";
export const SET_STUDENT_ATTENDANCE = "SET_STUDENT_ATTENDANCE";
export const SET_ATTENDANCE_FILTERS = "SET_ATTENDANCE_FILTERS";
export const GET_ATTENDANCE_PROGRAM_FILTER = "GET_ATTENDANCE_PROGRAM_FILTER";
export const SET_ATTENDANCE_PROGRAM_FILTER = "SET_ATTENDANCE_PROGRAM_FILTER";
export const GET_ATTENDANCE_STUDENT_FILTER = "GET_ATTENDANCE_STUDENT_FILTER";
export const SET_ATTENDANCE_STUDENT_FILTER = "SET_ATTENDANCE_STUDENT_FILTER";
export const GET_ATTENDANCE_POD_FILTER = "GET_ATTENDANCE_POD_FILTER";
export const SET_ATTENDANCE_POD_FILTER = "SET_ATTENDANCE_POD_FILTER";
export const GET_ATTENDANCE_CSV = "GET_ATTENDANCE_CSV";
export const ARCHIVE_STUDENTS = "ARCHIVE_STUDENTS";
export const SET_ARCHIVE_STUDENTS = "SET_ARCHIVE_STUDENTS";
export const EXPORT_PARENTS = "EXPORT_PARENTS";
export const SET_EXPORT_PARENTS = "SET_EXPORT_PARENTS";
export const DELETE_PARENTS = "DELETE_PARENTS";
export const SET_DELETE_PARENTS = "SET_DELETE_PARENTS";
export const EXPORT_STUDENT_DATA = "EXPORT_STUDENT_DATA";
