import fetch from "auth/FetchInterceptor";

const SignupService = {};

SignupService.getSchools = function fn(params) {
  return fetch({
    url: "/api/signup/schools",
    method: "GET",
    params: { ...params },
  });
};

SignupService.submitSignupForm = function fn(payload) {
  return fetch({
    url: "/api/signup",
    method: "POST",
    data: payload,
  });
};

export default SignupService;
