import fetch from "auth/FetchInterceptor";
import {
  DEFAULT_MESSAGEBOARD_PAGE_SIZE,
  DEFAULT_PAGE,
} from "constants/AppConstant";
import { STUDENT } from "constants/ThemeConstant";

const PodService = {};

PodService.getPodData = function fn(params) {
  return fetch({
    url: "/api/admin/pods",
    method: "GET",
    params: { ...params },
  });
};

PodService.createPod = function fn(pod) {
  return fetch({
    url: "/api/admin/pods",
    method: "POST",
    data: pod,
  });
};

PodService.getPodById = function fn(id) {
  return fetch({
    url: `/api/admin/pods/${id}`,
    method: "GET",
  });
};

PodService.editPod = function fn({ podId, values }) {
  return fetch({
    url: `/api/admin/pods/${podId}`,
    method: "PUT",
    data: values,
  });
};

PodService.archivePod = function fn(podId) {
  return fetch({
    url: `/api/admin/pods/${podId}/archive`,
    method: "POST",
  });
};

PodService.addStudentToPod = function fn({ podId, student }) {
  return fetch({
    url: `/api/admin/pods/${podId}/students`,
    method: "POST",
    data: student,
  });
};

PodService.addCoachToPod = function fn({ podId, coach }) {
  return fetch({
    url: `/api/admin/pods/${podId}/users`,
    method: "POST",
    data: coach,
  });
};

PodService.removeStudentFromPod = function fn({ podId, studentId }) {
  return fetch({
    url: `/api/admin/pods/${podId}/students/${studentId}`,
    method: "DELETE",
  });
};

PodService.removeCoachFromPod = function fn({ podId, coachId }) {
  return fetch({
    url: `/api/admin/pods/${podId}/users/${coachId}`,
    method: "DELETE",
  });
};

PodService.getPodMessageBoard = function fn({
  podId,
  userRole,
  currentPage = DEFAULT_PAGE,
  size = DEFAULT_MESSAGEBOARD_PAGE_SIZE,
}) {
  const user = userRole === STUDENT ? "students" : "staff";

  return fetch({
    url: `/api/${user}/pods/${podId}/messageBoard?page=${currentPage}&size=${size}`,
    method: "GET",
  });
};

PodService.addPostToMessageBoard = function fn({
  podId,
  userRole,
  content,
  parentMid,
}) {
  const user = userRole === STUDENT ? "students" : "staff";

  return fetch({
    url: `/api/${user}/pods/${podId}/messageBoard`,
    method: "POST",
    data: {
      content,
      parentMid,
    },
  });
};

PodService.deletePostFromMessageBoard = function fn({
  podId,
  messageId,
  userRole,
}) {
  const user = userRole === STUDENT ? "students" : "staff";

  return fetch({
    url: `/api/${user}/pods/${podId}/messageBoard/message/${messageId}`,
    method: "DELETE",
  });
};

PodService.getNotificationThread = function fn({ threadId, userRole }) {
  const user = userRole === STUDENT ? "students" : "staff";

  return fetch({
    url: `/api/${user}/notifications/${threadId}`,
    method: "GET",
  });
};

export default PodService;
