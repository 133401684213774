import React, { useEffect } from "react";
import { Row, Col, Card, notification } from "antd";
import AuthViews from "views/auth-views";
import { connect } from "react-redux";
import { hideNotification } from "redux/actions";

const SignupLayout = ({
  notificationMessage,
  notificationDescription,
  notificationType,
  notificationVisible,
  hideNotification,
}) => {
  useEffect(() => {
    if (notificationVisible) {
      notification[notificationType]({
        message: notificationMessage,
        description: notificationDescription,
        onClose: hideNotification,
      });
    }
  }, [
    notificationDescription,
    notificationMessage,
    notificationType,
    notificationVisible,
  ]);
  return (
    <div className="signup-container">
      <div className="vh-100 signup-page">
        <div className="container d-flex flex-column justify-content-center h-100">
          <Row justify="center">
            <Col lg={24}>
              <Card>
                <div>
                  <Row justify="center">
                    <Col lg={24}>
                      <AuthViews />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ common }) => {
  const {
    navigationConfig,
    notificationMessage,
    notificationDescription,
    notificationType,
    notificationVisible,
  } = common;
  return {
    navigationConfig,
    notificationMessage,
    notificationDescription,
    notificationType,
    notificationVisible,
  };
};

const mapDispatchToProps = {
  hideNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SignupLayout));
