import {
  HIDE_NOTIFICATION,
  SHOW_NOTIFICATION,
  SET_NAVIGATION_CONFIG,
} from "redux/constants";

const initState = {
  notificationMessage: "",
  notificationDescription: "",
  notificationType: "info",
  notificationVisible: false,
  navigationConfig: null,
};

const common = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SHOW_NOTIFICATION: {
      return {
        ...state,
        notificationMessage: payload.message,
        notificationDescription: payload.description,
        notificationType: payload.type,
        notificationVisible: true,
      };
    }
    case HIDE_NOTIFICATION: {
      return {
        ...state,
        notificationMessage: "",
        notificationDescription: "",
        notificationType: "info",
        notificationVisible: false,
      };
    }
    case SET_NAVIGATION_CONFIG: {
      return {
        ...state,
        navigationConfig: payload,
      };
    }
    default:
      return state;
  }
};

export default common;
