export const STOP_LOADING_STAFF_PORTAL = "STOP_LOADING_STAFF_PORTAL";
export const GET_STAFF_DASHBOARD_DATA = "GET_STAFF_DASHBOARD_DATA";
export const SET_STAFF_DASHBOARD_DATA = "SET_STAFF_DASHBOARD_DATA";
export const START_SESSION = "START_SESSION";
export const END_SESSION = "END_SESSION";
export const SUBMIT_ATTENDANCE = "SUBMIT_ATTENDANCE";
export const GET_STAFF_PAST_SESSIONS = "GET_STAFF_PAST_SESSIONS";
export const SET_STAFF_PAST_SESSIONS = "SET_STAFF_PAST_SESSIONS";
export const GET_STAFF_SESSION_BY_ID = "GET_STAFF_SESSION_BY_ID";
export const SET_STAFF_CURRENT_SESSION_POD = "SET_STAFF_CURRENT_SESSION_POD";
export const MARK_SUBMISSION = "MARK_SUBMISSION";
export const UPDATE_POD_LINK = "UPDATE_POD_LINK";
export const SET_CURRENT_POD_LINK = "SET_CURRENT_POD_LINK";
