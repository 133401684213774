import {
  EDIT_PARENT,
  GET_PARENT_DATA,
  SET_CURRENT_PARENT,
  SET_PARENT_DATA,
  STOP_LOADING_PARENT,
} from "redux/constants";

export const getParentData = (payload) => ({
  type: GET_PARENT_DATA,
  payload,
});

export const setParentData = (payload) => ({
  type: SET_PARENT_DATA,
  payload,
});

export const setCurrentParent = (payload) => ({
  type: SET_CURRENT_PARENT,
  payload,
});

export const editParent = (payload) => ({
  type: EDIT_PARENT,
  payload,
});

export const stopLoadingParent = (payload) => ({
  type: STOP_LOADING_PARENT,
  payload,
});
