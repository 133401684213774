import { THEME_CONFIG } from "configs/AppConfig";
import {
  TOGGLE_COLLAPSED_NAV,
  TOGGLE_MOBILE_NAV
} from "../constants/Theme";

const initTheme = {
  ...THEME_CONFIG,
};

const theme = (state = initTheme, action) => {
  switch (action.type) {
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };
    case TOGGLE_MOBILE_NAV:
      return {
        ...state,
        mobileNav: action.mobileNav,
      };
    default:
      return state;
  }
};

export default theme;
