import { SET_PROFILE, SET_ROLES, SET_NOTIFICATIONS } from "redux/constants";

const initState = {
  user: null,
};

const profile = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PROFILE: {
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    }
    case SET_ROLES: {
      return {
        ...state,
        user: { roles: payload },
      };
    }
    case SET_NOTIFICATIONS: {
      return {
        ...state,
        user: { ...state.user, notifications: payload },
      };
    }
    default:
      return state;
  }
};

export default profile;
