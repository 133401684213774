import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import PodService from "services/PodService";
import {
  ADD_COACH_TOP_POD,
  ADD_STUDENT_TO_POD,
  ARCHIVE_POD,
  CREATE_POD,
  EDIT_POD,
  GET_POD_BY_ID,
  GET_POD_DATA,
  GET_POD_MESSAGE_BOARD,
  REMOVE_COACH_FROM_POD,
  REMOVE_STUDENT_FROM_POD,
  ADD_POST_TO_MESSAGE_BOARD,
  DELETE_POST_FROM_MESSAGE_BOARD,
  GET_NOTIFICATION_THREAD,
} from "redux/constants";
import {
  getPodById,
  getPodData,
  setCurrentPod,
  setPodData,
  showNotification,
  stopLoadingPod,
  setPodMessageBoard,
  setNotificationThread,
} from "redux/actions";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "constants/AppConstant";
import { GENERIC_ERROR } from "constants/MessageConstant";

export function* fetchPodData({ payload }) {
  try {
    const data = yield call(PodService.getPodData, payload);
    if (data && data.pods) {
      const { count, totalPages, currentPage, pods } = data;
      yield put(
        setPodData({
          count,
          totalPages,
          currentPage,
          podList: pods,
          pageSize: payload.size,
        })
      );
    }
  } catch (err) {
    yield put(stopLoadingPod());
  }
}

export function* createPod({ payload }) {
  try {
    yield call(PodService.createPod, payload);
    yield put(
      showNotification({
        message: "New pod added.",
        description: `You have successfully added ${payload.name} to pods.`,
        type: "success",
      })
    );
    yield put(getPodData({ page: DEFAULT_PAGE, size: DEFAULT_PAGE_SIZE }));
  } catch (err) {
    yield put(stopLoadingPod());
    yield put(
      showNotification({
        message: "Failed to add new pod.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* fetchPodById({ payload }) {
  try {
    const data = yield call(PodService.getPodById, payload);
    if (data) {
      yield put(setCurrentPod(data));
    }
  } catch (err) {
    yield put(stopLoadingPod());
  }
}

export function* editPod({ payload }) {
  try {
    yield call(PodService.editPod, payload);
    yield put(
      showNotification({
        message: "Pod successfully updated.",
        type: "success",
      })
    );
    const { podId } = payload;
    yield put(getPodById(podId));
  } catch (err) {
    yield put(stopLoadingPod());
    yield put(
      showNotification({
        message: "Failed to update pod.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* archivePod({ payload: id }) {
  try {
    yield call(PodService.archivePod, id);
    yield put(
      showNotification({
        message: "Pod successfully archived.",
        type: "success",
      })
    );
    yield put(getPodById(id));
  } catch (err) {
    yield put(stopLoadingPod());
    yield put(
      showNotification({
        message: "Failed to archive pod.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* addStudentToPod({ payload }) {
  try {
    yield call(PodService.addStudentToPod, payload);
    yield put(
      showNotification({
        message: "Student successfully added to pod.",
        type: "success",
      })
    );
    const { podId } = payload;
    yield put(getPodById(podId));
  } catch (err) {
    yield put(stopLoadingPod());
    yield put(
      showNotification({
        message: "Failed to add student to pod.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* addCoachToPod({ payload }) {
  try {
    yield call(PodService.addCoachToPod, payload);
    yield put(
      showNotification({
        message: "Coach successfully added to pod.",
        type: "success",
      })
    );
    const { podId } = payload;
    yield put(getPodById(podId));
  } catch (err) {
    yield put(stopLoadingPod());
    yield put(
      showNotification({
        message: "Failed to add coach to pod.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* removeStudentFromPod({ payload }) {
  try {
    yield call(PodService.removeStudentFromPod, payload);
    yield put(
      showNotification({
        message: "Student successfully removed from pod.",
        type: "success",
      })
    );
    const { podId } = payload;
    yield put(getPodById(podId));
  } catch (err) {
    yield put(stopLoadingPod());
    yield put(
      showNotification({
        message: "Failed to remove student from pod.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* removeCoachFromPod({ payload }) {
  try {
    yield call(PodService.removeCoachFromPod, payload);
    yield put(
      showNotification({
        message: "Coach successfully removed from pod.",
        type: "success",
      })
    );
    const { podId } = payload;
    yield put(getPodById(podId));
  } catch (err) {
    yield put(stopLoadingPod());
    yield put(
      showNotification({
        message: "Failed to remove coach from pod.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* getPodMessageBoard({ payload }) {
  try {
    const data = yield call(PodService.getPodMessageBoard, payload);
    yield put(setPodMessageBoard(data));
  } catch (err) {
    console.log("Error: ", err);
  }
}

export function* addPostToMessageBoard({ payload }) {
  try {
    const res = yield call(PodService.addPostToMessageBoard, payload);

    if (res.message === "success") {
      const data = yield call(PodService.getPodMessageBoard, payload);

      yield put(setPodMessageBoard(data));
    }
  } catch (err) {
    console.log("ERROR: ", err);
  }
}

export function* deletePostFromMessageBoard({ payload }) {
  try {
    const res = yield call(PodService.deletePostFromMessageBoard, payload);

    if (res.message === "success") {
      const data = yield call(PodService.getPodMessageBoard, payload);
      yield put(setPodMessageBoard(data));
      yield put(
        showNotification({
          message: "Comment successfully deleted.",
          type: "success",
        })
      );
    }
  } catch (err) {
    console.log("ERROR: ", err);
  }
}

export function* getNotificationThread({ payload }) {
  try {
    const data = yield call(PodService.getNotificationThread, payload);
    yield put(setNotificationThread(data));
  } catch (err) {
    console.log("Error: ", err);
  }
}

export function* watchFetchPodData() {
  yield takeEvery(GET_POD_DATA, fetchPodData);
}

export function* watchCreatePod() {
  yield takeEvery(CREATE_POD, createPod);
}

export function* watchFetchPodById() {
  yield takeEvery(GET_POD_BY_ID, fetchPodById);
}

export function* watchEditPod() {
  yield takeEvery(EDIT_POD, editPod);
}

export function* watchArchivePod() {
  yield takeEvery(ARCHIVE_POD, archivePod);
}

export function* watchAddStudentToPod() {
  yield takeEvery(ADD_STUDENT_TO_POD, addStudentToPod);
}

export function* watchAddCoachToPod() {
  yield takeEvery(ADD_COACH_TOP_POD, addCoachToPod);
}

export function* watchRemoveStudentFromPod() {
  yield takeEvery(REMOVE_STUDENT_FROM_POD, removeStudentFromPod);
}

export function* watchRemoveCoachFromPod() {
  yield takeEvery(REMOVE_COACH_FROM_POD, removeCoachFromPod);
}

export function* watchGetPodMessageBoard() {
  yield takeEvery(GET_POD_MESSAGE_BOARD, getPodMessageBoard);
}

export function* watchAddPostToMessageBoard() {
  yield takeEvery(ADD_POST_TO_MESSAGE_BOARD, addPostToMessageBoard);
}

export function* watchDeletePostFromMessageBoard() {
  yield takeEvery(DELETE_POST_FROM_MESSAGE_BOARD, deletePostFromMessageBoard);
}

export function* watchGetNotificationThread() {
  yield takeEvery(GET_NOTIFICATION_THREAD, getNotificationThread);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchPodData),
    fork(watchCreatePod),
    fork(watchFetchPodById),
    fork(watchEditPod),
    fork(watchArchivePod),
    fork(watchAddStudentToPod),
    fork(watchAddCoachToPod),
    fork(watchRemoveStudentFromPod),
    fork(watchRemoveCoachFromPod),
    fork(watchGetPodMessageBoard),
    fork(watchAddPostToMessageBoard),
    fork(watchDeletePostFromMessageBoard),
    fork(watchGetNotificationThread),
  ]);
}
