import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import SignupLayout from "layouts/signup-layout";
import { ConfigProvider } from "antd";
import useBodyClass from "hooks/useBodyClass";
import AppLocale from "lang";
import { ADMIN, STAFF, STUDENT } from "constants/ThemeConstant";
import { AdminViews } from "./admin-views";
import { StaffViews } from "./staff-views";
import { StudentViews } from "./student-views";

function RouteInterceptor({ children, isAuthenticated, redirect, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Views = (props) => {
  const { locale, token, location, direction, userRole } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  return (
    <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
      <Switch>
        {/** account routes */}
        <Route exact path="/forgotpassword">
          <AuthLayout direction={direction} />
        </Route>
        <Route exact path="/forgotpasswordsuccess">
          <AuthLayout direction={direction} />
        </Route>
        <Route exact path="/resetpassword">
          <AuthLayout direction={direction} />
        </Route>
        <Route exact path="/resetpasswordsuccess">
          <AuthLayout direction={direction} />
        </Route>
        <Route exact path="/verify">
          <AuthLayout direction={direction} />
        </Route>
        <Route exact path="/verifysuccess">
          <AuthLayout direction={direction} />
        </Route>
        {/** admin auth routes */}
        <Route exact path="/admin/login">
          <AuthLayout direction={direction} />
        </Route>
        <RouteInterceptor
          path="/admin"
          redirect="/admin/login"
          isAuthenticated={token && userRole === ADMIN}
        >
          <AppLayout direction={direction} location={location}>
            <AdminViews />
          </AppLayout>
        </RouteInterceptor>
        <Route path="/admin">
          <Redirect to="/admin/student-management" />
        </Route>
        {/** staff auth routes */}
        <Route exact path="/staff/login">
          <AuthLayout direction={direction} />
        </Route>
        <RouteInterceptor
          path="/staff"
          redirect="/staff/login"
          isAuthenticated={token && userRole === STAFF}
        >
          <AppLayout direction={direction} location={location}>
            <StaffViews />
          </AppLayout>
        </RouteInterceptor>
        <Route path="/staff">
          <Redirect to="/staff/dashboard" />
        </Route>
        {/** student auth routes */}
        <Route exact path="/student/login">
          <AuthLayout direction={direction} />
        </Route>
        <Route exact path="/student/signup">
          <SignupLayout direction={direction} />
        </Route>
        <Route exact path="/student/signup/success">
          <SignupLayout direction={direction} />
        </Route>
        <RouteInterceptor
          path="/student"
          redirect="/student/login"
          isAuthenticated={token && userRole === STUDENT}
        >
          <AppLayout direction={direction} location={location}>
            <StudentViews />
          </AppLayout>
        </RouteInterceptor>
        <Route path="/student">
          <Redirect to="/student/dashboard" />
        </Route>
        {/** catch-all should redirect to student login for now */}
        <RouteInterceptor
          path="/"
          redirect="/student/login"
          isAuthenticated={false}
        />
      </Switch>
    </ConfigProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token, userRole } = auth;
  return { locale, token, direction, userRole };
};

export default withRouter(connect(mapStateToProps)(Views));
