import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "constants/AppConstant";
import {
  ADD_STAFF,
  EDIT_STAFF,
  GET_STAFF_DATA,
  SET_STAFF_DATA,
  SET_CURRENT_STAFF,
  STOP_LOADING_STAFF,
} from "redux/constants";

const initState = {
  loading: false,
  count: 0,
  totalPages: 0,
  currentPage: DEFAULT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  staffList: [],
  currentStaff: null,
};

const staff = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STOP_LOADING_STAFF: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_STAFF_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_STAFF_DATA: {
      const { staffList, count, totalPages, currentPage, pageSize } = payload;
      return {
        ...state,
        staffList,
        count,
        totalPages,
        currentPage,
        pageSize,
        loading: false,
      };
    }
    case ADD_STAFF: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_CURRENT_STAFF: {
      return {
        ...state,
        currentStaff: payload,
      };
    }
    case EDIT_STAFF: {
      return {
        ...state,
        loading: true,
      };
    }
    default:
      return state;
  }
};

export default staff;
