import React from 'react'

const Icon = (props) => {
		const { type, className  } = props;
		return (
			<>{React.createElement(type, { className })}</>
		)
};

export default Icon;
