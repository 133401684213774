import { FrownOutlined } from "@ant-design/icons";
import React from "react";

const iconStyles = {
  fontSize: "24px",
};

const MessageDeleted = () => (
  <div className="message-deleted">
    <FrownOutlined style={iconStyles} />
    <p>This message has been deleted.</p>
  </div>
);

export default MessageDeleted;
