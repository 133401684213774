import {
  DEFAULT_MESSAGEBOARD_PAGE_SIZE,
  DEFAULT_PAGE,
} from "constants/AppConstant";
import {
  CREATE_POD,
  EDIT_POD,
  GET_POD_BY_ID,
  GET_POD_DATA,
  SET_CURRENT_POD,
  SET_POD_DATA,
  SET_POD_MESSAGE_BOARD,
  STOP_LOADING_POD,
  CHANGE_MESSAGEBOARD_PAGE,
  SET_NOTIFICATION_THREAD,
  GET_NOTIFICATION_THREAD,
} from "redux/constants";

const initState = {
  loading: false,
  count: 0,
  totalPages: 0,
  currentPage: DEFAULT_PAGE,
  pageSize: DEFAULT_MESSAGEBOARD_PAGE_SIZE,
  podList: [],
  currentPod: null,
  selectedNotification: null,
  getNotificationThreadLoading: false,
};

const pod = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STOP_LOADING_POD: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_POD_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_POD_DATA: {
      const { podList, count, totalPages, currentPage, pageSize } = payload;
      return {
        ...state,
        podList,
        count,
        totalPages,
        currentPage,
        pageSize,
        loading: false,
      };
    }
    case CREATE_POD: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_POD_BY_ID: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_CURRENT_POD: {
      return {
        ...state,
        currentPod: payload,
        loading: false,
      };
    }
    case EDIT_POD: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_POD_MESSAGE_BOARD: {
      return {
        ...state,
        loading: false,
        messageBoard: payload,
      };
    }
    case CHANGE_MESSAGEBOARD_PAGE: {
      return {
        ...state,
        ...payload,
      };
    }
    case GET_NOTIFICATION_THREAD: {
      return {
        ...state,
        getNotificationThreadLoading: true,
      };
    }
    case SET_NOTIFICATION_THREAD: {
      return {
        ...state,
        selectedNotification: payload,
        getNotificationThreadLoading: false,
      };
    }
    default:
      return state;
  }
};

export default pod;
