import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import {
  ADD_STAFF,
  DELETE_STAFF,
  EDIT_STAFF,
  GET_STAFF_DATA,
  RESEND_ACTIVATION_EMAIL,
} from "redux/constants";
import {
  getStaffData,
  setStaffData,
  showNotification,
  stopLoadingStaff,
} from "redux/actions";
import StaffService from "services/StaffService";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "constants/AppConstant";
import { GENERIC_ERROR, SAVED_SUCCESS } from "constants/MessageConstant";

export function* fetchStaffData({ payload }) {
  try {
    const data = yield call(StaffService.getStaffData, payload);
    if (data && data.users) {
      const { count, totalPages, currentPage, users } = data;
      yield put(
        setStaffData({
          count,
          totalPages,
          currentPage,
          staffList: users,
          pageSize: payload.size,
        })
      );
    }
  } catch (err) {
    yield put(stopLoadingStaff());
  }
}

export function* addStaff({ payload }) {
  try {
    const staff = yield call(StaffService.addStaff, payload);
    if (staff) {
      yield put(
        showNotification({
          message: "New staff added.",
          description: `You have successfully added ${staff.firstName} ${staff.lastName} to staff.`,
          type: "success",
        })
      );
      yield put(
        getStaffData({
          page: DEFAULT_PAGE,
          size: DEFAULT_PAGE_SIZE,
          sortBy: ["updatedAt,DESC"],
        })
      );
    }
  } catch (err) {
    yield put(stopLoadingStaff());
    yield put(
      showNotification({
        message: "Failed to add staff.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* editStaff({ payload }) {
  try {
    yield call(StaffService.editStaff, payload);
    yield put(
      showNotification({
        message: "Staff info successfully updated.",
        description: SAVED_SUCCESS,
        type: "success",
      })
    );
    yield put(getStaffData({ page: DEFAULT_PAGE, size: DEFAULT_PAGE_SIZE }));
  } catch (err) {
    yield put(stopLoadingStaff());
    yield put(
      showNotification({
        message: "Failed to update staff info.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* deleteStaff({ payload }) {
  try {
    yield call(StaffService.deleteStaff, payload);
    yield put(
      showNotification({
        message: "Staff successfully deleted.",
        type: "success",
      })
    );
    yield put(getStaffData({ page: DEFAULT_PAGE, size: DEFAULT_PAGE_SIZE }));
  } catch (err) {
    yield put(stopLoadingStaff());
    yield put(
      showNotification({
        message: "Failed to delete staff.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* resendActivationEmail({ payload }) {
  try {
    yield call(StaffService.resendActivationEmail, payload);
    yield put(
      showNotification({
        message: "User activation email sent!",
        description: "The activation email should arrive shortly.",
        type: "success",
      })
    );
  } catch (err) {
    yield put(stopLoadingStaff());
    yield put(
      showNotification({
        message: "Failed to send user activation email.",
        description: GENERIC_ERROR,
        type: "error",
      })
    );
  }
}

export function* watchFetchStaffData() {
  yield takeEvery(GET_STAFF_DATA, fetchStaffData);
}

export function* watchAddStaff() {
  yield takeEvery(ADD_STAFF, addStaff);
}

export function* watchEditStaff() {
  yield takeEvery(EDIT_STAFF, editStaff);
}

export function* watchDeleteStaff() {
  yield takeEvery(DELETE_STAFF, deleteStaff);
}

export function* watchResendActivationEmail() {
  yield takeEvery(RESEND_ACTIVATION_EMAIL, resendActivationEmail);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchStaffData),
    fork(watchAddStaff),
    fork(watchEditStaff),
    fork(watchDeleteStaff),
    fork(watchResendActivationEmail),
  ]);
}
