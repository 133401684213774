const dev = {
  API_ENDPOINT_URL:
    // "http://start2finish-lb-dev-2036943931.ca-central-1.elb.amazonaws.com",
    // "http://api.qa.brainworx.start2finishonline.org/",
    // "http://start2finish-lb-qa-1956344113.ca-central-1.elb.amazonaws.com",
    "http://localhost:5000",
};

const prod = {
  API_ENDPOINT_URL: "https://api.brainworx.start2finishonline.org",
};

const qa = {
  API_ENDPOINT_URL:
    "http://start2finish-lb-qa-1956344113.ca-central-1.elb.amazonaws.com",
};

const buildEnv = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV;

const getEnv = () => {
  let env = dev;
  switch (buildEnv) {
    case "development":
      env = dev;
      break;
    case "production":
      env = prod;
      break;
    case "qa":
      env = qa;
      break;
    default:
      break;
  }
  return env;
};

const env = getEnv();

export default env;
