import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "constants/AppConstant";
import {
  ADD_STUDENT,
  EDIT_STUDENT,
  GET_STUDENT_DATA,
  SET_STUDENT_DATA,
  STOP_LOADING_STUDENT,
  SET_PASSPHRASE,
  SET_STUDENT_ATTENDANCE,
  GET_STUDENT_ATTENDANCE,
  SET_ATTENDANCE_PROGRAM_FILTER,
  SET_ATTENDANCE_STUDENT_FILTER,
  SET_ATTENDANCE_POD_FILTER,
  SET_ARCHIVE_STUDENTS,
  SET_EXPORT_PARENTS,
  SET_DELETE_PARENTS,
  SET_ATTENDANCE_FILTERS,
} from "redux/constants";

const initState = {
  loading: false,
  count: 0,
  totalPages: 0,
  currentPage: DEFAULT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  studentList: [],
  passphrase: "",
  studentsArchived: false,
  parentsExported: false,
  parentsDeleted: false,
  attendanceFilters: {
    page: 1,
    size: 10,
    studentIds: [],
    programIds: [],
    podIds: [],
    year: new Date().getFullYear(),
    dateSort: "DESC",
    attendanceSort: "DESC",
  },
};

const student = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STOP_LOADING_STUDENT: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_STUDENT_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_STUDENT_DATA: {
      const { studentList, count, totalPages, currentPage, pageSize } = payload;
      return {
        ...state,
        studentList,
        count,
        totalPages,
        currentPage,
        pageSize,
        loading: false,
      };
    }
    case ADD_STUDENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case EDIT_STUDENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_PASSPHRASE: {
      return {
        ...state,
        passphrase: payload,
      };
    }
    case GET_STUDENT_ATTENDANCE: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_STUDENT_ATTENDANCE: {
      return {
        ...state,
        loading: false,
        attendance: payload,
      };
    }
    case SET_ATTENDANCE_PROGRAM_FILTER: {
      return {
        ...state,
        programFilter: payload,
      };
    }
    case SET_ATTENDANCE_STUDENT_FILTER: {
      return {
        ...state,
        studentFilter: payload,
      };
    }
    case SET_ATTENDANCE_POD_FILTER: {
      return {
        ...state,
        podFilter: payload,
      };
    }
    case SET_ARCHIVE_STUDENTS: {
      return {
        ...state,
        studentsArchived: payload,
      };
    }
    case SET_EXPORT_PARENTS: {
      return {
        ...state,
        parentsExported: payload,
      };
    }
    case SET_DELETE_PARENTS: {
      return {
        ...state,
        parentsDeleted: payload,
      };
    }
    case SET_ATTENDANCE_FILTERS: {
      return {
        ...state,
        attendanceFilters: payload,
      };
    }
    default:
      return state;
  }
};

export default student;
