export const GET_PROFILE = "GET_PROFILE";
export const SET_PROFILE = "SET_PROFILE";
export const UPLOAD_PHOTO = "UPLOAD_PHOTO";
export const REMOVE_PHOTO = "REMOVE_PHOTO";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SET_ROLES = "SET_ROLES";
export const GET_AVATARS = "GET_AVATARS";
export const SAVE_AVATAR = "SAVE_AVATAR";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
