import React from "react";
import { connect } from "react-redux";
import { Grid, Image } from "antd";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_TOP,
} from "constants/ThemeConstant";
import { APP_NAME } from "configs/AppConfig";
import utils from "utils";
import S2FLogoIcon from "assets/svg/S2F_Logo_Icon.svg";
import S2FLogo from "assets/svg/S2F_Logo_EN.svg";

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP;
  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return "auto";
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  }
  return `${SIDE_NAV_WIDTH}px`;
};

const getLogo = (props) => {
  const { navCollapsed } = props;
  if (navCollapsed) {
    return S2FLogoIcon;
  }
  return S2FLogo;
};

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return "d-none";
  }
  return "logo";
};

const Logo = (props) => {
  const { userRole, mobileLogo } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  return (
    <div
      className={`${getLogoDisplay(isMobile, mobileLogo)} ${userRole}-logo`}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
      <Image
        src={getLogo(props)}
        alt={`${APP_NAME} logo`}
        width={120}
        preview={false}
      />
    </div>
  );
};

const mapStateToProps = ({ auth, theme }) => {
  const { userRole } = auth;
  const { navCollapsed, navType } = theme;
  return { userRole, navCollapsed, navType };
};

export default connect(mapStateToProps)(Logo);
