import fetch from "auth/FetchInterceptor";

const StudentService = {};

StudentService.getStudentData = function fn(params) {
  return fetch({
    url: "/api/admin/students",
    method: "GET",
    params: { ...params },
  });
};

StudentService.addStudent = function fn(student) {
  return fetch({
    url: "/api/admin/students",
    method: "POST",
    data: student,
  });
};

StudentService.editStudent = function fn({ id, values }) {
  return fetch({
    url: `/api/admin/students/${id}`,
    method: "PUT",
    data: values,
  });
};

StudentService.deleteStudent = function fn(id) {
  return fetch({
    url: `/api/admin/students/${id}`,
    method: "DELETE",
  });
};

StudentService.getPassphrase = function fn() {
  return fetch({
    url: "/api/admin/users/generatepassphrase",
    method: "GET",
  });
};

StudentService.getStudentAttendance = function fn({
  page,
  size,
  studentIds,
  programIds,
  podIds,
  year,
  dateSort,
  attendanceSort,
}) {
  return fetch({
    url: "/api/admin/attendance",
    method: "POST",
    data: {
      page,
      size,
      studentIds,
      programIds,
      podIds,
      year,
      dateSort,
      attendanceSort,
    },
  });
};

StudentService.getAttendanceProgramFilter = function fn({
  studentIds,
  programIds,
  podIds,
}) {
  return fetch({
    url: "/api/admin/attendance/filter/program",
    method: "POST",
    data: { studentIds, programIds, podIds },
  });
};

StudentService.getAttendanceStudentFilter = function fn({
  studentIds,
  programIds,
  podIds,
  year,
}) {
  return fetch({
    url: "/api/admin/attendance/filter/student",
    method: "POST",
    data: { studentIds, programIds, podIds, year },
  });
};

StudentService.getAttendancePodFilter = function fn({
  studentIds,
  programIds,
  podIds,
}) {
  return fetch({
    url: "/api/admin/attendance/filter/pod",
    method: "POST",
    data: { studentIds, programIds, podIds },
  });
};

StudentService.getAttendanceCSV = function fn({
  page,
  size,
  studentIds,
  year,
  programIds,
  podIds,
  dateSort,
  attendanceSort,
}) {
  return fetch({
    url: "/api/admin/attendance/download",
    method: "POST",
    data: {
      page,
      size,
      studentIds,
      year,
      programIds,
      podIds,
      dateSort,
      attendanceSort,
    },
  });
};

StudentService.triggerArchiveStudents = function fn() {
  return fetch({
    url: "/api/admin/endterm/archive-active-students",
    method: "PUT",
  });
};

StudentService.triggerExportParents = function fn() {
  return fetch({
    url: "/api/admin/endterm/export-parents",
    method: "GET",
  });
};

StudentService.triggerDeleteParents = function fn() {
  return fetch({
    url: "/api/admin/endterm/archive-active-parents",
    method: "PUT",
  });
};

StudentService.triggerExportStudentData = function fn() {
  return fetch({
    url: "/api/admin/students/data/export",
    method: "GET",
  });
};

export default StudentService;
