import fetch from "auth/FetchInterceptor";

const StaffPortalService = {};

StaffPortalService.getStaffDashboardData = function fn() {
  return fetch({
    url: "/api/staff/dashboard",
    method: "GET",
  });
};

StaffPortalService.startSession = function fn({ podId, sessionId }) {
  return fetch({
    url: `/api/staff/pods/${podId}/sessions/${sessionId}/startSession`,
    method: "POST",
  });
};

StaffPortalService.endSession = function fn({ podId, sessionId }) {
  return fetch({
    url: `/api/staff/pods/${podId}/sessions/${sessionId}/endSession`,
    method: "POST",
  });
};

StaffPortalService.submitAttendance = function fn({
  podId,
  sessionId,
  attendance,
}) {
  return fetch({
    url: `/api/staff/pods/${podId}/sessions/${sessionId}/attendance`,
    method: "POST",
    data: attendance,
  });
};

StaffPortalService.getStaffPastSessions = function fn() {
  return fetch({
    url: "/api/staff/sessions",
    method: "GET",
  });
};

StaffPortalService.getStaffSessionById = function fn({ podId, sessionId }) {
  return fetch({
    url: `/api/staff/pods/${podId}/sessions/${sessionId}`,
    method: "GET",
  });
};

StaffPortalService.markSubmission = function fn({
  podId,
  sessionId,
  submission,
}) {
  return fetch({
    url: `/api/staff/pods/${podId}/sessions/${sessionId}/submission`,
    method: "POST",
    data: submission,
  });
};

StaffPortalService.updatePodLink = function fn({ podId, values }) {
  return fetch({
    url: `/api/staff/pods/${podId}/link`,
    method: "POST",
    data: values,
  });
};

export default StaffPortalService;
