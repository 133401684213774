import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import AuthService from "services/AuthService";
import { BASIC_STAFF_USER_ROLES } from "constants/AppConstant";
import { ADMIN, STAFF, STUDENT } from "constants/ThemeConstant";
import { GENERIC_ERROR, INVALID_USER } from "constants/MessageConstant";
import {
  authenticateAdminSuccess,
  authenticateUserSuccess,
  forgotPasswordSuccess,
  getProfile,
  loginAsUserSuccess,
  resetPasswordSuccess,
  setRoles,
  showAuthMessage,
  verifyAccountSuccess,
} from "redux/actions";
import {
  AUTH_TOKEN,
  AUTHENTICATE_ADMIN,
  AUTHENTICATE_USER,
  FORGOT_PASSWORD,
  LOGIN_AS_USER,
  RESET_PASSWORD,
  VERIFY_ACCOUNT,
} from "redux/constants";

function getAuthenticatedUser(role, roles) {
  const authenticatedStaff =
    role === STAFF &&
    BASIC_STAFF_USER_ROLES.filter((staffRole) => roles.includes(staffRole.key))
      .length > 0;
  const authenticatedStudent = role === STUDENT && roles.includes(role);
  return { authenticatedStaff, authenticatedStudent };
}

export function* authenticateAdmin({ payload }) {
  try {
    const { accessToken, roles } = yield call(AuthService.loginAdmin, payload);
    if (accessToken && roles.includes(ADMIN)) {
      localStorage.setItem(AUTH_TOKEN, accessToken);
      yield put(
        authenticateAdminSuccess({
          token: accessToken,
          role: ADMIN,
        })
      );
      yield put(setRoles(roles));
      yield put(getProfile(ADMIN));
    } else {
      throw new Error();
    }
  } catch (err) {
    const { message } = err.response ? err.response.data : err;
    yield put(
      showAuthMessage({
        messageContent: message ?? INVALID_USER,
        messageType: "error",
      })
    );
  }
}

export function* authenticateUser({ payload }) {
  try {
    const { role } = payload;
    const { accessToken, roles } = yield call(AuthService.loginUser, payload);
    const { authenticatedStaff, authenticatedStudent } = getAuthenticatedUser(
      role,
      roles
    );
    if (accessToken && (authenticatedStaff || authenticatedStudent)) {
      localStorage.setItem(AUTH_TOKEN, accessToken);
      yield put(authenticateUserSuccess({ token: accessToken, role }));
      yield put(setRoles(roles));
      yield put(getProfile(role));
    } else {
      throw new Error();
    }
  } catch (err) {
    const { message } = err.response ? err.response.data : err;
    yield put(
      showAuthMessage({
        messageContent: message ?? INVALID_USER,
        messageType: "error",
      })
    );
  }
}

export function* verifyAccount({ payload }) {
  try {
    const { roles } = yield call(AuthService.verifyAccount, payload);
    const userRoles = roles.map((roleObj) => roleObj.role);
    let userRole = STAFF;
    if (userRoles.includes(ADMIN)) userRole = ADMIN;
    yield put(verifyAccountSuccess(userRole));
  } catch (err) {
    yield put(
      showAuthMessage({
        messageContent: GENERIC_ERROR,
        messageType: "error",
      })
    );
  }
}

export function* forgotPassword({ payload }) {
  try {
    yield call(AuthService.forgotPassword, payload);
    yield put(forgotPasswordSuccess());
  } catch (err) {
    yield put(
      showAuthMessage({
        messageContent: GENERIC_ERROR,
        messageType: "error",
      })
    );
  }
}

export function* resetPassword({ payload }) {
  try {
    const { roles } = yield call(AuthService.resetPassword, payload);
    const userRoles = roles.map((roleObj) => roleObj.role);
    let userRole = STAFF;
    if (userRoles.includes(ADMIN)) userRole = ADMIN;
    yield put(resetPasswordSuccess(userRole));
  } catch (err) {
    yield put(
      showAuthMessage({
        messageContent: GENERIC_ERROR,
        messageType: "error",
      })
    );
  }
}

export function* loginAsUser({ payload }) {
  try {
    const { role } = payload;
    const { accessToken, roles, impersonatorId } = yield call(
      AuthService.loginAsUser,
      payload
    );
    const { authenticatedStaff, authenticatedStudent } = getAuthenticatedUser(
      role,
      roles
    );
    console.log(authenticatedStaff, authenticatedStudent);
    if (accessToken && (authenticatedStaff || authenticatedStudent)) {
      localStorage.setItem(AUTH_TOKEN, accessToken);
      yield put(authenticateUserSuccess({ token: accessToken, role }));
      yield put(setRoles(roles));
      yield put(getProfile(role));
      yield put(loginAsUserSuccess(impersonatorId));
    } else {
      throw new Error();
    }
  } catch (err) {
    const { message } = err.response ? err.response.data : err;
    yield put(
      showAuthMessage({
        messageContent: message ?? INVALID_USER,
        messageType: "error",
      })
    );
  }
}

export function* watchAuthenticateAdmin() {
  yield takeEvery(AUTHENTICATE_ADMIN, authenticateAdmin);
}

export function* watchAuthenticateUser() {
  yield takeEvery(AUTHENTICATE_USER, authenticateUser);
}

export function* watchVerifyAccount() {
  yield takeEvery(VERIFY_ACCOUNT, verifyAccount);
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchLoginAsUser() {
  yield takeEvery(LOGIN_AS_USER, loginAsUser);
}

export default function* rootSaga() {
  yield all([
    fork(watchAuthenticateAdmin),
    fork(watchAuthenticateUser),
    fork(watchVerifyAccount),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchLoginAsUser),
  ]);
}
