/* eslint-disable global-require */
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../reducers";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "profile"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);
store.persistor = persistor;

/* istanbul ignore if */
if (module.hot) {
  module.hot.accept("../reducers/index", () => {
    const nextRootReducer = require("../reducers/index").default;
    store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
    store.persistor.persist();
  });
}

export { store, persistor, sagaMiddleware };
