import { SHOW_NOTIFICATION, HIDE_NOTIFICATION, SET_NAVIGATION_CONFIG } from "redux/constants";

export const showNotification = (payload) => ({
  type: SHOW_NOTIFICATION,
  payload
});

export const hideNotification = () => ({
  type: HIDE_NOTIFICATION,
});

export const setNavigationConfig = (payload) => ({
  type: SET_NAVIGATION_CONFIG,
  payload
})