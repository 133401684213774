import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "constants/AppConstant";
import {
  GET_SCHOOL_DATA,
  SET_SCHOOL_DATA,
  STOP_LOADING_SCHOOL,
} from "redux/constants";

const initState = {
  loading: false,
  count: 0,
  totalPages: 0,
  currentPage: DEFAULT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  schoolList: [],
};

const school = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STOP_LOADING_SCHOOL: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_SCHOOL_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_SCHOOL_DATA: {
      const { schoolList, count, totalPages, currentPage, pageSize } = payload;
      return {
        ...state,
        schoolList,
        count,
        totalPages,
        currentPage,
        pageSize,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default school;
