import {
  GET_REGIONS,
  SET_REGIONS,
} from "redux/constants/OrganizationConstants";

// eslint-disable-next-line import/prefer-default-export
export const getRegions = () => ({
  type: GET_REGIONS,
});

export const setRegions = (payload) => ({
  type: SET_REGIONS,
  payload,
});
