import { all, takeEvery, fork, call } from "redux-saga/effects";
import { UPDATE_EMERGENCY_CONTACT } from "redux/constants";
import EmergencyContactService from "services/EmergencyContactService";
import { showNotification } from "redux/actions";

// Emergency contact is created automatically when creating a new student
// either by using the signup form or the admin portal.
// This function will not likely be needed unless changes to the creation
// process are made.
export function* createEmergencyContact({ payload }) {
  try {
    yield call(EmergencyContactService.createEmergencyContact, payload);

    showNotification({
      message: "Emergency contact successfully created.",
      type: "success",
    });
  } catch (err) {
    console.log("Error: ", err);
  }
}

export function* updateEmergencyContact({ payload }) {
  try {
    yield call(EmergencyContactService.updateEmergencyContact, payload);

    showNotification({
      message: "Emergency contact successfully updated.",
      type: "success",
    });
  } catch (err) {
    console.log("Error: ", err);
  }
}

export function* watchUpdateEmergencyContact() {
  yield takeEvery(UPDATE_EMERGENCY_CONTACT, updateEmergencyContact);
}

export default function* rootSaga() {
  yield all([fork(watchUpdateEmergencyContact)]);
}
