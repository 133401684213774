import React, { useEffect, useState } from "react";
import {
  Avatar,
  Comment,
  List,
  Popconfirm,
  Button,
  Image,
  Divider,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { addPostToMessageBoard } from "redux/actions";
import { connect } from "react-redux";
import { STAFF, STUDENT } from "constants/ThemeConstant";
import IconDelete from "assets/svg/icon_delete.svg";
import Utils from "utils";
import Editor from "./Editor";

const MessageThread = ({
  selectedMessage,
  userRole,
  addPostToMessageBoard,
  user,
  deleteComment,
  currentPage,
  pageSize,
}) => {
  const [replies, setReplies] = useState(selectedMessage.replies);
  const [newPost, setNewPost] = useState("");

  useEffect(() => {
    setReplies(selectedMessage?.replies);
  }, [selectedMessage]);

  const handleTextAreaChange = (e) => {
    setNewPost(e.target.value);
  };

  const addComment = () => {
    const id = selectedMessage ? selectedMessage?.parentMessage?.id : null;

    addPostToMessageBoard({
      content: newPost,
      parentMid: id,
      userRole,
      podId: selectedMessage?.podId,
      currentPage,
      size: pageSize,
    });

    setReplies([
      ...replies,
      {
        message: {
          content: newPost,
        },
        sender: {
          firstName: user.firstName,
          lastName: user.lastName,
          profileImage: user.profileImage,
        },
      },
    ]);

    setNewPost("");
  };

  const handleDeleteReply = (id) => {
    deleteComment(id, selectedMessage?.podId);
    const newReplies = replies.filter((r) => r.message.id !== id);

    setReplies(newReplies);
  };

  return (
    <div className="message-thread">
      <div>
        <Comment
          className="comment"
          author={
            <span className="message-author">
              {selectedMessage?.sender?.firstName}{" "}
              {selectedMessage?.sender?.lastName}
            </span>
          }
          avatar={
            <Avatar
              src={selectedMessage?.sender?.profileImage}
              alt={`${selectedMessage?.sender?.firstName} ${selectedMessage?.sender?.lastName}`}
            />
          }
          icon={<UserOutlined />}
          content={
            <div>
              <p className="message-content">
                {selectedMessage?.parentMessage?.content}
              </p>
              <p className="message-timestamp">
                {Utils.formatNotificationDate(
                  selectedMessage?.parentMessage?.createdAt
                )}
              </p>
            </div>
          }
        />

        <Divider orientation="left">{replies?.length} Replies</Divider>
        {replies.length > 0 && (
          <List
            itemLayout="horizontal"
            dataSource={replies}
            renderItem={(message) => (
              <li>
                <Comment
                  className="comment"
                  author={
                    <span className="message-author">
                      {message?.sender?.firstName} {message?.sender?.lastName}
                    </span>
                  }
                  icon={<UserOutlined />}
                  avatar={
                    <Avatar
                      src={message?.sender?.profileImage}
                      alt={`${message?.sender?.firstName} ${message?.sender?.lastName}`}
                    />
                  }
                  content={
                    <div>
                      <p className="message-content">
                        {message?.message?.content}
                      </p>
                      <p className="message-timestamp">
                        {Utils.formatNotificationDate(
                          message?.message?.createdAt
                        )}
                      </p>
                    </div>
                  }
                  actions={[
                    userRole === STAFF ||
                    (userRole === STUDENT &&
                      message?.sender?.id === user.id) ? (
                      <Popconfirm
                        title="Are you sure you want to delete this comment?"
                        placement="topRight"
                        onConfirm={() =>
                          handleDeleteReply(message?.message?.id)
                        }
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button className="comment__delete" type="text">
                          <Image
                            src={IconDelete}
                            alt="delete-icon"
                            preview={false}
                          />
                        </Button>
                      </Popconfirm>
                    ) : null,
                  ]}
                />
              </li>
            )}
            className="message-thread__list"
          />
        )}
      </div>
      <Editor
        handleTextAreaChange={handleTextAreaChange}
        addComment={addComment}
        newPost={newPost}
      />
    </div>
  );
};

const mapStateToProps = ({ profile, auth, pod }) => {
  const { user } = profile;
  const { userRole } = auth;
  const { messageBoard, currentPage, pageSize } = pod;
  return {
    user,
    userRole,
    messageBoard,
    currentPage,
    pageSize,
  };
};

const mapDispatchToProps = { addPostToMessageBoard };

export default connect(mapStateToProps, mapDispatchToProps)(MessageThread);
